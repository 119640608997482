import Highcharts from 'highcharts';

export const horizontalColumnChartOptions: Highcharts.Options = {
  chart: {
    type: 'bar',
  },
  xAxis: [
    {
      reversed: false,
      labels: {
        step: 1,
      },
    },
  ],
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        format: '{y}%',
      },
    },
  },
};

import React, { useMemo } from 'react';
import './Chart.scss';
import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import { ChartService } from './services/ChartService';
import { globalChartOptions } from './constants/options/globalChartOptions';
import { GenericChartProps } from './interfaces/GenericChartProps';
import { OptionsListProp } from './types/OptionsListProp';
import { SeriesValueFormatter } from './types/SeriesValueFormatter';
import PageLoader from '../loader/PageLoader';
import HtmlStringFormatter from '../HtmlStringFormatter';
import { useClassName } from '../../hooks/useClassName';
import { ChartBlock, ChartElement } from './ChartBem';
import ChartContent from './ChartContent';

HighchartsExporting(Highcharts);
Highcharts.setOptions(globalChartOptions);

export interface ChartProps extends GenericChartProps {
  optionsList: OptionsListProp;
  isStock?: boolean;
  seriesValueFormatter?: SeriesValueFormatter;
  className?: string;
  isLoading?: boolean;
  hasNoIndents?: boolean;
  chartRef?: any;
}

const Chart: React.FC<ChartProps> = props => {
  const cn = useClassName(ChartBlock.Root, props.className);
  const options = useMemo(
    () => ChartService.getChartOptions(props.optionsList, props.seriesValueFormatter, props.hasNoIndents),
    [props.optionsList, props.seriesValueFormatter, props.hasNoIndents],
  );

  const constructorType = useMemo<keyof typeof Highcharts>(() => (props.isStock ? 'stockChart' : 'chart'), [
    props.isStock,
  ]);

  return (
    <div
      className={cn({
        [`${props.colorScheme}`]: props.colorScheme,
      })}
    >
      {props.title && <div className={cn(ChartElement.Title)}>{props.title}</div>}

      {props.filters && <div className={cn(ChartElement.Filters)}>{props.filters}</div>}

      <div className={cn(ChartElement.Chart)}>
        <PageLoader otherwise={ChartService.hasData(options)} loading={props.isLoading}>
          {ChartService.hasData(options) && (
            <ChartContent options={options} constructorType={constructorType} chartRef={props.chartRef} />
          )}
        </PageLoader>
      </div>

      {props.description && (
        <div className="Chart__description">
          <HtmlStringFormatter content={props.description} />
        </div>
      )}
    </div>
  );
};

export default Chart;

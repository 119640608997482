import { ResearchFlowProps } from './ResearchFlowItem';

export const researchFlowProps: ResearchFlowProps[] = [
  {
    title: 'Private Company Watchlist',
    baseImageUrl: require('../../shared/images/PCW_IPOIntelligence_base.png'),
    imageUrl: require('../../shared/images/PCW_IPOIntelligence.png'),
  },
  {
    title: 'IPO Pipeline',
    baseImageUrl: require('../../shared/images/Pipeline_IPOIntelligence_base.png'),
    imageUrl: require('../../shared/images/Pipeline_IPOIntelligence.png'),
  },
  {
    title: 'Profile',
    baseImageUrl: require('../../shared/images/Profile_IPOIntelligence_base.png'),
    imageUrl: require('../../shared/images/Profile_IPOIntelligence.png'),
  },
  {
    title: 'Mgmt Questions',
    baseImageUrl: require('../../shared/images/MgmtQuestions_IPOIntelligence_base.png'),
    imageUrl: require('../../shared/images/MgmtQuestions_IPOIntelligence.png'),
  },
  {
    title: 'Pre-IPO Research',
    baseImageUrl: require('../../shared/images/ABNB_Report_IPOIntelligence_a_base.png'),
    imageUrl: require('../../shared/images/ABNB_Report_IPOIntelligence_a.png'),
  },
  {
    title: '',
    imageUrl: require('../../shared/images/ABNB_Report_IPOIntelligence_b.png'),
  },
  {
    title: '',
    imageUrl: require('../../shared/images/ABNB_Report_IPOIntelligence_c.png'),
  },
  {
    title: '',
    imageUrl: require('../../shared/images/ABNB_Report_IPOIntelligence_d.png'),
  },

  {
    title: '',
    imageUrl: require('../../shared/images/ABNB_Report_IPOIntelligence_e.png'),
  },

  {
    title: '',
    imageUrl: require('../../shared/images/ABNB_Report_IPOIntelligence_f.png'),
  },

  {
    title: '',
    imageUrl: require('../../shared/images/ABNB_Report_IPOIntelligence_g.png'),
  },
  {
    title: 'Comp Table / DCF Model',
    baseImageUrl: require('../../shared/images/ABNB_CompTable_IPOIntelligence_a_base.png'),
    imageUrl: require('../../shared/images/ABNB_CompTable_IPOIntelligence_a.png'),
  },

  {
    title: '',
    imageUrl: require('../../shared/images/ABNB_Model_IPOIntelligence_b.png'),
  },
  {
    title: 'Pricing Insights',
    baseImageUrl: require('../../shared/images/PricingNote_IPOIntelligence_base.png'),
    imageUrl: require('../../shared/images/PricingNote_IPOIntelligence.png'),
  },
];

export const coverageFlowProps: ResearchFlowProps[] = [
  {
    title: 'Historical IPO Database',
    imageUrl: require('../../shared/images/features.png'),
  },
  {
    title: 'Quarterly and Annual Reviews',
    imageUrl: require('../../shared/images/features.png'),
  },
  {
    title: 'Trading Tools & Screens',
    imageUrl: require('../../shared/images/features.png'),
  },
];

import React from 'react';
import { useClassName } from '../../hooks/useClassName';
import './PrimarySectionAdditionalAction.scss';
import InnerComponent from '../inner-component/InnerComponent';
import { PrimarySectionSubheaderOptions } from './interfaces/PrimarySectionSubheaderOptions';
import { PrimarySectionSubheaderBlock } from './primarySectionSubheaderBem';

interface Props {
  options: PrimarySectionSubheaderOptions;
}

const PrimarySectionSubheader: React.FC<Props> = props => {
  const cn = useClassName(PrimarySectionSubheaderBlock.Root);

  return <InnerComponent className={cn()} options={props.options} />;
};

export default PrimarySectionSubheader;

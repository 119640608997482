import React, { memo } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { isEqual } from 'lodash';

interface Props {
  options: Highcharts.Options;
  constructorType: keyof typeof Highcharts;
  chartRef?: any;
}

const ChartContent: React.FC<Props> = props => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={props.options}
      constructorType={props.constructorType}
      immutable={true}
      ref={props.chartRef}
    />
  );
};

export default memo(ChartContent, isEqual);

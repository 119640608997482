import React from 'react';
import './InnerComponent.scss';
import { useClassName } from '../../hooks/useClassName';
import { InnerComponentBlock, InnerComponentElement } from './InnerComponentBem';
import { InnerComponentOptions } from './/interfaces/InnerComponentOptions';
import { AlignTypesHorizontal } from '../../enums/AlignTypesHorizontal';

interface Props {
  className: string;
  options: InnerComponentOptions;
}

const InnerComponent: React.FC<Props> = props => {
  const { alignTypeHorizontal = AlignTypesHorizontal.Start } = props.options;

  const cn = useClassName(InnerComponentBlock.Root, props.className);

  return (
    <div className={cn()}>
      <div className={cn(InnerComponentElement.Content, { [alignTypeHorizontal]: alignTypeHorizontal })}>
        {props.options.component}
      </div>
    </div>
  );
};

export default InnerComponent;

import React from 'react';
import CarouselWithNewspapers from '../../shared/CarouselWithNewspapers';
import './OverviewFeatured.scss';

const OverviewFeatured: React.FC = () => {
  return (
    <div className="OverviewFeatured">
      <div className="OverviewFeatured__title">Featured on:</div>

      <div className="OverviewFeatured__carousel">
        <CarouselWithNewspapers />
      </div>
    </div>
  );
};

export default OverviewFeatured;

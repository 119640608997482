import { ScreenshotDescriptionProps } from '../shared/ScreenshotDescription';

export const researchScreenshotDescriptionProps: ScreenshotDescriptionProps = {
  title: "Delivering the best ipo research to the world's top investment firms for over 30 years",
  description:
    'IPO Intelligence gives you an actionable ' +
    'investment opinion on every major IPO. ' +
    'You`ll get the real bull-bear case with an ' +
    'in-depth valuation analysis in enough ' +
    'time to use it.',
  imageUrl: require('../shared/images/features.png'),
};

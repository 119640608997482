import React from 'react';
import './ResearchFlowItem.scss';

export interface ResearchFlowProps {
  title: string;
  imageUrl: string;
  openLightBox?: () => void;
  baseImageUrl?: string;
}

const ResearchFlowItem: React.FC<ResearchFlowProps> = props => {
  return (
    <>
      {props.title === '' ? null : (
        <li className="ResearchFlowItem">
          <header className="ResearchFlowItem__header">
            <h6 onClick={props.openLightBox} className="ResearchFlowItem__title">
              <span>{props.title}</span>
            </h6>
            <div className="ResearchFlowItem__icon" />
          </header>

          <div className="ResearchFlowItem__content">
            <img
              className="ResearchFlowItem__image"
              src={props.baseImageUrl ? props.baseImageUrl : props.imageUrl}
              alt=""
              onClick={props.openLightBox}
            />
          </div>
        </li>
      )}
    </>
  );
};

export default ResearchFlowItem;

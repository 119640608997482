import React from 'react';
import Feature from '../shared/Feature';
import RequestTrial from '../shared/RequestTrial';
//import AboutTeam from './AboutTeam';
import AboutUnits from './ipoUnits/AboutUnits';
import { unitProps } from './unitProps';
//import { ColorScheme } from '../../shared/enums/ColorScheme';
import DocumentHead from '../../shared/components/DocumentHead';
import { OptimizedPictureType } from '../shared/optimized-picture/enums/OptimizedPictureType';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import { RoutePath } from '../../shared/enums/RoutePath';

const About: React.FC = () => {
  return (
    <section className="About">
      <DocumentHead path={RoutePath.About} />

      <Feature header="IPO EXPERTS SINCE 1991" optimizedPictureType={OptimizedPictureType.AboutDoorLogo}>
        Renaissance Capital has been providing comprehensive IPO research to institutional investors
        <span className="d-lg-block d-xl-block" /> for over 30 years. Find out why the largest and most active
        institutional IPO investors from
        <span className="d-lg-block d-xl-block" /> around the world rely on our IPO Intelligence.
      </Feature>

      <PrimarySection>
        <AboutUnits units={unitProps} />
      </PrimarySection>

      {/* <PrimarySection colorScheme={ColorScheme.Gray}>
        <AboutTeam />
      </PrimarySection> */}

      <RequestTrial title="Request a free trial today" />
    </section>
  );
};

export default About;

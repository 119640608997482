import React, { useMemo } from 'react';
import './AnnualRatingsPerformanceChart.scss';
import { annualRatingsPerformanceChart } from './constants/annualRatingsPerfomanceChart';
import { ChartColorScheme } from '../../components/chart/enums/ChartColorScheme';
import { AnnualRatingsPerformanceChartDto } from './interfaces/AnnualRatingsPerformanceChartDto';
import { ChartFactory } from '../../components/chart/factories/ChartFactory';
import { useClassName } from '../../hooks/useClassName';
import { AnnualRatingsPerformanceChartBlock } from './annualRatingsPerformanceChartBem';

interface Props {
  options?: AnnualRatingsPerformanceChartDto;
}

const AnnualRatingsPerformanceChart: React.FC<Props> = props => {
  const cn = useClassName(AnnualRatingsPerformanceChartBlock.Root);

  const chartProps = useMemo(
    () => ({
      className: cn(),
      optionsList: [props.options, annualRatingsPerformanceChart],
      colorScheme: ChartColorScheme.Performance,
    }),
    [props.options, cn],
  );

  const chart = useMemo(() => ChartFactory.getChart(chartProps), [chartProps]);

  return <>{chart}</>;
};

export default AnnualRatingsPerformanceChart;

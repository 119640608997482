import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducer as header } from './header/reducers';
import { reducer as notification } from './notification/reducers';

const rootReducer = combineReducers({
  header,
  notification,
});

export type RootState = ReturnType<typeof rootReducer>;

export const getStore = () => createStore(rootReducer, applyMiddleware(thunk));

export const store = getStore();

import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './HeaderPublicMenu.scss';
import { RoutePath } from '../../enums/RoutePath';
import {
  IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US,
  IPO_INTELLIGENCE_CLIENT_SITE_FREE_TRIAL,
  IPO_INTELLIGENCE_CLIENT_SITE_LOGIN_URL,
} from '../../constants/urls';

const HeaderPublicMenu: FunctionComponent = () => {
  return (
    <nav className="HeaderPublicMenu">
      <ul className="HeaderPublicMenu__list">
        <li className="HeaderPublicMenu__item HeaderPublicMenu__item--contact">
          <a href={IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US} rel="nofollow noopener noreferrer">
            Contact Us &gt;
          </a>
        </li>

        <li className="HeaderPublicMenu__item">
          <a
            className="btn btn-sm btn-outline-primary text-uppercase"
            href={IPO_INTELLIGENCE_CLIENT_SITE_LOGIN_URL}
            rel="nofollow noopener noreferrer"
          >
            Login
          </a>
        </li>

        <li className="HeaderPublicMenu__item">
          <a
            className="HeaderPublicMenu__request btn btn-sm text-uppercase"
            href={IPO_INTELLIGENCE_CLIENT_SITE_FREE_TRIAL}
            rel="nofollow noopener noreferrer"
          >
            Free trial
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderPublicMenu;

export enum NumberFormat {
  Integer = '0',
  Float = '0.0',
  FloatDecades = '0.00',
  FloatHundreds = '0.000',
  FloatWithComma = '0,0.0',
  WithComma = '0,0',
  WithLetter = '0.0 a',
  PercentageInteger = '0%',
  PercentageFloat = '0.0%',
  PercentageFloatDecades = '0.00%',
  PercentageFloatHundreds = '0.000%',
  CurrencyIntegerWithLetter = '($0 a)',
  CurrencyFloatWithLetter = '($0.0 a)',
  CurrencyFloatDecadesWithLetter = '($0.00 a)',
  Sequence = '0o',
}

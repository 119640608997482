import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Overview from '../marketing/overview/Overview';
import Research from '../marketing/research/Research';
import Features from '../marketing/features/Features';
import WhoUsesUs from '../marketing/whoUsesUs/WhoUsesUs';
import Pricing from '../marketing/pricing/Pricing';
import About from '../marketing/about/About';
import NotFound from '../shared/components/NotFound';
import UserPrivacy from '../marketing/userPrivacy/UserPrivacy';
import Disclosures from '../disclosures/Disclosures';
import { RoutePath } from '../shared/enums/RoutePath';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={RoutePath.Home}>
        <Redirect to={RoutePath.Overview} />
      </Route>
      *{' '}
      <Route path={RoutePath.Features}>
        <Research />
      </Route>
      <Route path={RoutePath.WhoUsesUs}>
        <WhoUsesUs />
      </Route>
      <Route path={RoutePath.Pricing}>
        <Pricing />
      </Route>
      <Route path={RoutePath.About}>
        <About />
      </Route>
      <Route path={RoutePath.Overview}>
        <Overview />
      </Route>
      <Route path={RoutePath.Disclosures}>
        <Disclosures />
      </Route>
      <Route path={RoutePath.UserPrivacy}>
        <UserPrivacy />
      </Route>
      <Route path={RoutePath.Research}>
        <Research />
      </Route>
      <Route path={RoutePath.NotFound}>
        <NotFound />
      </Route>
      <Redirect to={RoutePath.NotFound} />
    </Switch>
  );
};

export default Routes;

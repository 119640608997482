import Highcharts from 'highcharts';
import { HtmlString } from '../../../../types/HtmlString';
import { ChartTooltipFormatterService } from '../../services/ChartTooltipFormatterService';
import { CustomAny } from '../../../../types/generics';
import { ChartValuesType } from '../../enums/ChartValuesType';
import { NumberHelper } from '../../../../helpers/NumberHelper';
import { NumberFormat } from '../../../../enums/NumberFormat';

export const verticalPercentageColumnChartOptions: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  yAxis: {
    stackLabels: {
      enabled: false,
    },
  },
  tooltip: {
    formatter(): HtmlString {
      return ChartTooltipFormatterService.getTooltip({
        points: this.points as CustomAny[],
        title: `${this.x}`,
        pointsValueType: ChartValuesType.Percentage,
      });
    },
    shared: true,
  },
  plotOptions: {
    column: {
      dataLabels: {
        formatter(): HtmlString {
          return NumberHelper.getFormatValue(this.y, NumberFormat.PercentageInteger);
        },
      },
      stacking: 'percent',
    },
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
};

import React, { ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.scss';
import classNames from 'classnames';

interface Props {
  description?: ReactNode;
  className?: string;
}

const Loader: React.FC<Props> = props => {
  return (
    <div className={classNames('Loader', props.className)}>
      <Spinner animation="border" size="sm" /> {props.description}
    </div>
  );
};

export default Loader;

import React from 'react';
import './SizeText.scss';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';

export interface SizeTextProps {
  size?: ControlSize;
  className?: string;
}

const SizeText: React.FC<SizeTextProps> = props => {
  const { size = ControlSize.Medium, className } = props;

  const cn = useClassName('SizeText', className);

  return <div className={cn({ [size]: size })}>{props.children}</div>;
};

export default SizeText;

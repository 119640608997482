import React from 'react';
import { horizontalColumnChartOptions } from '../constants/options/horizontalColumnChartOptions';
import Chart, { ChartProps } from '../Chart';
import { lineStockChartOptions } from '../constants/options/lineStockChartOptions';
import { ChartValueFormatters } from '../services/ChartValueFormatters';
import { percentageColumnChartOptions } from '../constants/options/percentageColumnChartOptions';
import { ChartType } from '../enums/ChartType';
import { verticalColumnChartOptions } from '../constants/options/verticalColumnChartOptions';
import { verticalPercentageColumnChartOptions } from '../constants/options/verticalPercentageColumnChartOptions';
import { verticalCurrencyChartOptions } from '../constants/options/verticalCurrencyChartOptions';

export class ChartFactory {
  private static getChartComponent(
    chartProps: ChartProps,
    additionalOptions?: Highcharts.Options,
    chartRef?: any,
  ): React.ReactNode {
    const optionsList = additionalOptions ? chartProps.optionsList.concat([additionalOptions]) : chartProps.optionsList;
    const props = { ...chartProps, optionsList };

    return <Chart {...props} chartRef={chartRef} />;
  }

  private static getBase(props: ChartProps): React.ReactNode {
    return this.getChartComponent(props);
  }

  private static getVerticalColumn(props: ChartProps): React.ReactNode {
    return this.getChartComponent(props, verticalColumnChartOptions);
  }

  private static getHorizontalColumn(props: ChartProps): React.ReactNode {
    const chartProps: ChartProps = {
      ...props,
      seriesValueFormatter: ChartValueFormatters.percentageValueFormatter,
    };

    return this.getChartComponent(chartProps, horizontalColumnChartOptions);
  }

  private static getLineStock(props: ChartProps, chartRef?: any) {
    const chartProps: ChartProps = {
      ...props,
      isStock: true,
    };

    return this.getChartComponent(chartProps, lineStockChartOptions, chartRef);
  }

  private static getPercentageColumn(props: ChartProps) {
    const chartProps: ChartProps = {
      ...props,
      seriesValueFormatter: ChartValueFormatters.percentageValueFormatter,
    };

    return this.getChartComponent(chartProps, percentageColumnChartOptions);
  }

  private static getVerticalPercentageColumn(props: ChartProps) {
    return this.getChartComponent(props, verticalPercentageColumnChartOptions);
  }

  private static getVerticalCurrencyColumn(props: ChartProps) {
    return this.getChartComponent(props, verticalCurrencyChartOptions);
  }

  static getChart(props: ChartProps, type?: ChartType, chartRef?: any) {
    switch (type) {
      case ChartType.Base:
        return this.getBase(props);
      case ChartType.HorizontalColumn:
        return this.getHorizontalColumn(props);
      case ChartType.LineStock:
        return this.getLineStock(props, chartRef);
      case ChartType.PersentageColumn:
        return this.getPercentageColumn(props);
      case ChartType.VerticalColumn:
        return this.getVerticalColumn(props);
      case ChartType.VerticalPercentageColumn:
        return this.getVerticalPercentageColumn(props);
      case ChartType.VerticalCurrencyColumn:
        return this.getVerticalCurrencyColumn(props);
      default:
        return this.getBase(props);
    }
  }
}

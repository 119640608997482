import React from 'react';
import { IconName } from '../../../shared/components/icon/IconName';
import { ScreenshotDescriptionProps } from '../../shared/ScreenshotDescription';

export const featuresProps: ScreenshotDescriptionProps[] = [
  {
    title: 'Back your decisions with unbiased ratings',
    description:
      'Renaissance Capital has been rating ' +
      'IPOs for over 30 years. Our clients ' +
      'know that we call them like we see ' +
      'them. All of our ratings are completely ' +
      'independent and unbiased, so you ' +
      'know you’re getting research opinions, ' +
      'not Wall Street sales pitches.',
    icon: IconName.GrowingChart,
    imageUrl: require('../../shared/images/UnbiasedRatings_IPOIntelligence_resized.png'),
    layoutStyle: 'wide',
    reverse: true,
  },
  {
    title: (
      <>
        Find IPO<small>s</small> with Alpha
      </>
    ),
    description:
      'Our team of analysts review and rate all ' +
      'major IPOs based on the long-term ' +
      'and short-term outlook. Let us do the ' +
      'heavy lifting so you have more time to ' +
      'analyze the winning deals.',
    icon: IconName.Eye,
    imageUrl: require('../../shared/images/IPOsWithAlpha_IPOIntelligence.png'),
  },
  {
    title: 'Don’t waste time on the laggards',
    description: 'Steer clear of the underperforming IPOs, so you can make your bets in the right places.',
    icon: IconName.Magnifier,
    imageUrl: require('../../shared/images/DontWasteTime_IPOIntelligence.png'),
    reverse: true,
  },
  {
    title: 'Get plugged in to the IPO market',
    description:
      'The IPO market moves quickly. IPO Intelligence ' +
      'makes tracking upcoming ' +
      'deals easy, so you can spend your time ' +
      'where you need to most.',
    icon: IconName.Spyglass,
    imageUrl: require('../../shared/images/GetPluggedIn_IPOIntelligence.png'),
  },
  {
    title: 'Get the objective bull and bear case',
    description:
      'Get a feel for a deal as quickly as possible. ' +
      'Bull and bear insights on each IPO give you ' +
      'a quick summary of the deal, and highlight ' +
      'the points you should focus on in your research.',
    icon: IconName.Chess,
    imageUrl: require('../../shared/images/ObjectiveBullBear_IPOIntelligence.png'),
    reverse: true,
  },
  {
    title: 'ANALYZE EVERY SCENARIO WITH DYNAMIC COMP TABLES AND CUSTOMIZED DCF MODELS',
    description:
      'Our Excel DCF models allows users to ' +
      'scrutinize line-by-line our forward ' +
      'financial estimates, measure the effect ' +
      'of different growth forecasts on our fair ' +
      'value estimate, and integrate the data ' +
      'with their own investment platforms.',
    icon: IconName.Sheet,
    baseImageUrl: require('../../shared/images/ABNB_CompTable_IPOIntelligence_a_base_wide.png'),
    imageUrl: [
      require('../../shared/images/ABNB_CompTable_IPOIntelligence_a.png'),
      require('../../shared/images/ABNB_Model_IPOIntelligence_b.png'),
    ],
  },
  {
    title: 'Get timely, actionable research',
    description:
      'Our research is timely. You get early ' +
      'deal color with company profiles up ' +
      'within hours of a filing, Management ' +
      'Questions in your hands in time for the ' +
      'roadshow or 1-on-1’s, and full research ' +
      'reports out before the deal prices.',
    icon: IconName.Clock,
    imageUrl: require('../../shared/images/TimelyActionable_IPOIntelligence.png'),
    reverse: true,
  },
];

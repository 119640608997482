import React from 'react';
import './OverviewFeatures.scss';
import ScreenshotDescription, { ScreenshotDescriptionProps } from '../../shared/ScreenshotDescription';
import { featuresProps } from './featuresProps';
import PrimarySection from '../../../shared/components/primary-section/PrimarySection';
import { ColorScheme } from '../../../shared/enums/ColorScheme';

const OverviewFeatures: React.FC = () => {
  return (
    <div className="OverviewFeatures">
      {featuresProps.map((props: ScreenshotDescriptionProps, index: number) => (
        <PrimarySection key={index} colorScheme={props.reverse ? undefined : ColorScheme.Gray}>
          <ScreenshotDescription {...props} />
        </PrimarySection>
      ))}
    </div>
  );
};

export default OverviewFeatures;

export enum ColorScheme {
  Primary = 'primary',
  Secondary = 'secondary',
  Info = 'info',
  Attention = 'attention',
  Success = 'success',
  Gray = 'gray',
  LightGray = 'lightgray',
  Danger = 'danger',
  White = 'white',
}

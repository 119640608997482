import Highcharts from 'highcharts';
import { defaultsDeep, cloneDeep, set } from 'lodash';
import { baseChartOptions } from '../constants/options/baseChartOptions';
import { OptionsListProp } from '../types/OptionsListProp';
import { SeriesValueFormatter } from '../types/SeriesValueFormatter';
import {
  CHART_WITH_RANGE_SELECTOR_CHART_MARGIN_TOP,
  CHART_WITHOUT_RANGE_SELECTOR_CHART_MARGIN_TOP,
} from '../constants/options/chartOptionsSettings';

export class ChartService {
  static getChartOptions(
    optionsList: OptionsListProp,
    seriesValueFormatter?: SeriesValueFormatter,
    hasNoIndents?: boolean,
  ): Highcharts.Options {
    let options = this.getOptions(optionsList);

    if (seriesValueFormatter) {
      options = this.getFormattedSeries(options, seriesValueFormatter);
    }

    if (hasNoIndents) {
      const hasCredits = options.credits?.enabled || options.credits?.text;

      options.chart = {
        ...options.chart,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: hasCredits ? undefined : 0,
      };
    }

    const chartMarginTopPath = 'chart.marginTop';
    if (options.rangeSelector) {
      set(options, chartMarginTopPath, CHART_WITH_RANGE_SELECTOR_CHART_MARGIN_TOP);
    } else {
      set(options, chartMarginTopPath, CHART_WITHOUT_RANGE_SELECTOR_CHART_MARGIN_TOP);
    }

    return options;
  }

  static hasData(options: Highcharts.Options): boolean {
    return !!options?.series?.length;
  }

  private static getOptions(optionsList: OptionsListProp): Highcharts.Options {
    return defaultsDeep({}, ...optionsList, baseChartOptions);
  }

  private static getFormattedSeries(
    options: Highcharts.Options,
    seriesValueFormatter: SeriesValueFormatter,
  ): Highcharts.Options {
    const updatedOptions = cloneDeep(options);

    updatedOptions.series = updatedOptions?.series?.map(seriesOption => seriesValueFormatter(seriesOption));

    return updatedOptions;
  }
}

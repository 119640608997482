import React from 'react';
import { OrderedListItem } from '../../shared/components/ordered-list/interfaces/OrderedListItem';

export const userPrivacyInfo: OrderedListItem[] = [
  {
    title: 'What information do we track, and why',
    description:
      'This website uses Google Analytics, a web analytics service provided by Google. Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyze how users use the site. We track users flow, location by country, language, browser and OS, Internet Service Provider, devices. We track this information so we can improve our service to our clients.',
  },
  {
    title: "What information we don't track",
    description:
      'We will not upload any data that allows Google to personally identify an individual (such as name, Social Security Number, email address, or any similar data), or data that permanently identifies a particular device (such as a unique device identifier if such an identifier cannot be reset).',
  },
  {
    title: 'Users options',
    description: (
      <>
        Website users who don&apos;t want their data collected with Google Analytics can install the Google Analytics
        opt-out browser add-on. This add-on instructs the Google Analytics script running on websites to prohibit
        sending information to Google Analytics. To opt-out of Analytics for the web, visit the{' '}
        <a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer nofollow">
          Google Analytics opt-out page
        </a>{' '}
        and install the add-on for your browser. For more details on installing and uninstalling the add-on, please see
        the relevant help resources for your specific browser. Read more about Google Analytics opt-out browser add-on{' '}
        <a href="https://support.google.com/analytics/answer/181881" rel="noopener noreferrer nofollow">
          here
        </a>
        . Users can also opt-out on our website by clicking{' '}
        <a href="https://www.renaissancecapital.com/" rel="noopener noreferrer nofollow">
          here
        </a>
        .
      </>
    ),
  },
];

import React, { ReactNode } from 'react';
import './Feature.scss';
import { OptimizedPictureType } from './optimized-picture/enums/OptimizedPictureType';
import OptimizedPicture from './optimized-picture/OptimizedPicture';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import { PrimarySectionIndent } from '../../shared/components/primary-section/PrimarySectionIndent';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import { IPO_INTELLIGENCE_CLIENT_SITE_FREE_TRIAL } from '../../shared/constants/urls';
import LinkButton from '../../shared/components/forms/LinkButton';
import { FREE_TRIAL_LINK_BUTTON_TEXT } from '../../shared/constants/linkText';

interface Props {
  header: string | ReactNode;
  optimizedPictureType: OptimizedPictureType;
}

const Feature: React.FC<Props> = props => {
  return (
    <div className="Feature">
      <PrimarySection header={props.header} indent={PrimarySectionIndent.Xxl}>
        <PrimarySectionRow>
          <div className="Feature__description">{props.children}</div>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <LinkButton linkUrl={IPO_INTELLIGENCE_CLIENT_SITE_FREE_TRIAL} linkText={FREE_TRIAL_LINK_BUTTON_TEXT} />
        </PrimarySectionRow>

        <div className="Feature__overflow" />

        <OptimizedPicture type={props.optimizedPictureType} />
      </PrimarySection>
    </div>
  );
};

export default Feature;

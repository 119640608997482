export enum RoutePath {
  Home = '/',
  Research = '/ipo-research',
  Features = '/features',
  WhoUsesUs = '/who-uses-us',
  Pricing = '/pricing',
  About = '/about',
  Overview = '/overview',
  UserPrivacy = '/user-privacy',
  Disclosures = '/disclosures',
  GettingStarted = '/getting-started',
  Videos = '/videos',
  Faq = '/faq',
  NotFound = '/not-found',
}

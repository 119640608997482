import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { DOCUMENT_HEAD, DocumentHeadData, DocumentHeadRoutePath } from '../constants/documentHead';

interface CustomProps {
  custom: DocumentHeadData;
}

interface PathProps {
  path: DocumentHeadRoutePath;
}

const COMPANY_NAME = 'IPO Intelligence - Renaissance Capital';

const DocumentHead: React.FC<PathProps | CustomProps> = props => {
  const headData = useMemo(() => {
    const path = (props as PathProps).path;

    if (path) {
      return DOCUMENT_HEAD[path];
    }

    return (props as CustomProps).custom;
  }, [props]);

  const compositeDescription = useMemo(() => {
    if (headData.description) {
      return `${headData.description} | ${COMPANY_NAME}`;
    }

    return COMPANY_NAME;
  }, [headData]);

  return (
    <Helmet
      title={headData.title}
      defaultTitle={COMPANY_NAME}
      titleTemplate={`%s | ${COMPANY_NAME}`}
      meta={[
        {
          name: 'description',
          content: compositeDescription,
        },
      ]}
    />
  );
};

export default DocumentHead;

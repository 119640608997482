import React from 'react';
import './RequestTrial.scss';
import PropTypes from 'prop-types';
import { useClassName } from '../../shared/hooks/useClassName';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import { ColorScheme } from '../../shared/enums/ColorScheme';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import { AlignTypesHorizontal } from '../../shared/enums/AlignTypesHorizontal';
import LinkButton from '../../shared/components/forms/LinkButton';
import { IPO_INTELLIGENCE_CLIENT_SITE_FREE_TRIAL } from '../../shared/constants/urls';
import { FREE_TRIAL_SIGN_UP_LINK_BUTTON_TEXT } from '../../shared/constants/linkText';

interface Props {
  title?: string;
  button?: string;
  large?: boolean;
}

const RequestTrial: React.FC<Props> = props => {
  const { large } = props;

  const cn = useClassName('RequestTrial');

  return (
    <div className={cn()}>
      <PrimarySection
        colorScheme={ColorScheme.Primary}
        header={<div className={cn('title', { large })}>{props.title}</div>}
      >
        <PrimarySectionRow alignHorizontal={AlignTypesHorizontal.Center}>
          <LinkButton
            linkUrl={IPO_INTELLIGENCE_CLIENT_SITE_FREE_TRIAL}
            linkText={FREE_TRIAL_SIGN_UP_LINK_BUTTON_TEXT}
          />{' '}
        </PrimarySectionRow>
      </PrimarySection>
    </div>
  );
};

RequestTrial.propTypes = {
  title: PropTypes.string,
  button: PropTypes.string,
  large: PropTypes.bool,
};

RequestTrial.defaultProps = {
  title: 'Request a free trial today',
};

export default RequestTrial;

import React from 'react';
import Carousel from '../../shared/components/carousel/Carousel';
import CarouselItem from '../../shared/components/carousel/CarouselItem';

const CarouselWithNewspapers: React.FC = () => (
  <Carousel>
    <CarouselItem>
      <img src={require('./images/clients/wsj-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/the-new-york-times-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/bloomberg-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/fox-news-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/cnn-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/forbes-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/investors-business-daily-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/cnbc-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/fortune-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/barrons-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/npr-logo.png')} alt="" />
    </CarouselItem>
  </Carousel>
);

export default CarouselWithNewspapers;

import React from 'react';
import HeaderPublicMenu from './HeaderPublicMenu';
import './Header.scss';
import HeaderLogo from './HeaderLogo';
import HeaderMainMenu from './HeaderMainMenu';

const Header: React.FC = () => {
  return (
    <header className="Header">
      <HeaderLogo />
      <div className="Header__slogan">Helping Institutional Investors Profit from IPOs.</div>
      <HeaderPublicMenu />

      <div className="Header__mainMenu">
        <HeaderMainMenu />
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { PrimarySectionAdditionalActionOptions } from './interfaces/PrimarySectionAdditionalActionOptions';
import './PrimarySectionAdditionalAction.scss';
import InnerComponent from '../inner-component/InnerComponent';
import { PrimarySectionAdditionalActionBlock } from './primarySectionAdditionalActionBem';

interface Props {
  options: PrimarySectionAdditionalActionOptions;
}

const PrimarySectionAdditionalAction: React.FC<Props> = props => {
  return <InnerComponent className={PrimarySectionAdditionalActionBlock.Root} options={props.options} />;
};

export default PrimarySectionAdditionalAction;

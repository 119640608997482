export enum PrimarySectionRowBlock {
  Root = 'PrimarySectionRow',
}

export enum PrimarySectionRowElement {
  Header = 'header',
  HeaderContent = 'headerContent',
  Content = 'content',
}

export enum PrimarySectionRowModifier {
  NoBorder = 'noBorder',
}

import React, { FunctionComponent } from 'react';
import './QuotesItem.scss';

interface Props {
  quote: string;
  author: string;
}

const QuotesItem: FunctionComponent<Props> = props => {
  return (
    <blockquote className="QuotesItem">
      <q className="QuotesItem__content">{props.quote}</q>
      <cite className="QuotesItem__author">{props.author}</cite>
    </blockquote>
  );
};

export default QuotesItem;

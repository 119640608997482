import React, { useState } from 'react';
import './App.scss';
import MainMenu from './shared/components/main-menu/MainMenu';
import Header from './shared/components/header/Header';
import Routes from './routes/Routes';
import { Router } from 'react-router-dom';
import { history } from './shared/history';
import classNames from 'classnames';
import PrimarySection from './shared/components/primary-section/PrimarySection';
import { ColorScheme } from './shared/enums/ColorScheme';
import { PrimarySectionIndent } from './shared/components/primary-section/PrimarySectionIndent';
import Footer from './shared/components/footer/Footer';
import { useHistoryChange } from './shared/effects/useHistoryChange';
import { ScrollService } from './shared/services/ScrollService';
import { useClassName } from './shared/hooks/useClassName';
import { AppBlock, AppElement } from './AppBem';
import LocationService from './shared/services/LocationService';
import ReactGA from 'react-ga';

const App: React.FC = () => {
  const cn = useClassName(AppBlock.Root);

  React.useEffect(() => {
    const trackingId = 'UA-49249493-2';
    ReactGA.initialize(trackingId);

    // Initialize google analytics page view tracking
    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
  }, []);

  const gaUserId = sessionStorage.getItem('gaUserId');
  const gaAccountId = sessionStorage.getItem('gaAccountId');
  React.useEffect(() => {
    ReactGA.set({
      dimension3: gaAccountId,
      dimension5: gaUserId,
    });
  }, [gaUserId, gaAccountId]);

  useHistoryChange((prevLocation, location) => {
    ScrollService.scrollToTop();
    LocationService.reloadPageIfSameLocation(prevLocation, location);
  });

  return (
    <div className={cn()}>
      {true && (
        <Router history={history}>
          <section className={cn(AppElement.Header)}>
            <PrimarySection indent={PrimarySectionIndent.Compact}>
              <Header />
            </PrimarySection>

            <PrimarySection colorScheme={ColorScheme.Primary} indent={PrimarySectionIndent.None}>
              <MainMenu />
            </PrimarySection>
          </section>

          <section className={classNames('App__content')}>
            <Routes />
          </section>

          <section className="App__footer">
            <PrimarySection colorScheme={ColorScheme.Primary} indent={PrimarySectionIndent.Internal}>
              <Footer />
            </PrimarySection>
          </section>
        </Router>
      )}
    </div>
  );
};

export default App;

import React, { CSSProperties } from 'react';
import './OverlayContainer.scss';
import { useClassName } from '../../hooks/useClassName';
import { OverlayContainerBlock, OverlayContainerElement } from './OverlayContainerBem';

interface Props {
  className?: string;
  isBackgroundFill?: boolean;
  hasBackground?: boolean;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  onContainerClick?: () => void;
  onContainerMouseDown?: () => void;
  onContainerMouseUp?: () => void;
}

const OverlayContainer: React.FC<Props> = props => {
  const { hasBackground = true } = props;

  const cn = useClassName(OverlayContainerBlock.Root, props.className);

  return (
    <div
      className={cn({
        backgroundFill: props.isBackgroundFill,
        hasBackground: hasBackground,
      })}
      style={props.style}
      onClick={props.onContainerClick}
      onMouseDown={props.onContainerMouseDown}
      onMouseUp={props.onContainerMouseUp}
    >
      <div className={cn(OverlayContainerElement.Overlay)} />
      <div style={props.contentStyle} className={cn(OverlayContainerElement.Content)}>
        {props.children}
      </div>
    </div>
  );
};

export default OverlayContainer;

import { ReducerAction } from '../../shared/interfaces/ReducerAction';

export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';
export const SET_ADDITIONAL_MENU_VISIBILITY = 'SET_ADDITIONAL_MENU_VISIBILITY';

export type State = {
  isMobileMenuOpen: boolean;
  isAdditionalMenuVisible: boolean;
};

export type ToggleMobileMenuAction = ReducerAction<typeof TOGGLE_MOBILE_MENU, {}>;
export type CloseMobileMenuAction = ReducerAction<typeof CLOSE_MOBILE_MENU, {}>;
export type SetAdditionalMenuVisibility = ReducerAction<
  typeof SET_ADDITIONAL_MENU_VISIBILITY,
  {
    isAdditionalMenuVisible: boolean;
  }
>;

export type ActionTypes = ToggleMobileMenuAction | CloseMobileMenuAction | SetAdditionalMenuVisibility;

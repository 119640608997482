import Highcharts from 'highcharts';

export const lineStockChartOptions: Highcharts.Options = {
  chart: {
    type: 'line',
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  navigator: { enabled: false },
  scrollbar: { enabled: false },
};

import React from 'react';
import AboutUnit, { AboutUnitProps } from './AboutUnit';
import './AboutUnits.scss';
import { RoutePath } from '../../../shared/enums/RoutePath';
import { Link } from 'react-router-dom';
import { AboutUnitTextElement } from './enum/AboutUnitTextElement';
import { IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US } from '../../../shared/constants/urls';

interface Props {
  units: AboutUnitProps[];
}

const AboutUnits: React.FC<Props> = props => {
  return (
    <section className="AboutUnits">
      <div className="AboutUnits-row">
        {props.units.map((item: AboutUnitProps, index: number) => (
          <div key={index} className="AboutUnits-row__column">
            <AboutUnit {...item} />
          </div>
        ))}
      </div>

      <h5 className="AboutUnits__link">
        Contact us{' '}
        <a href={IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US} rel="nofollow noopener noreferrer">
          {AboutUnitTextElement.Contact}
        </a>{' '}
        if you have any questions.
      </h5>
    </section>
  );
};

export default AboutUnits;

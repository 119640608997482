import React from 'react';
import { optimizedPicturesLinks } from './constatns/optimizedPicturesLinks';
import { OptimizedPictureType } from './enums/OptimizedPictureType';

interface Props {
  type: OptimizedPictureType;
}

const OptimizedPicture: React.FC<Props> = props => {
  const images = optimizedPicturesLinks[props.type];

  return (
    <picture className="OptimizedPicture">
      <source
        type="image/webp"
        srcSet={
          `${images.webp576} 576w,` +
          `${images.webp768} 768w,` +
          `${images.webp992} 992w,` +
          `${images.webp1200} 1200w,` +
          `${images.webp} 1515w`
        }
        sizes="(max-width: 576px) 576px,
        (max-width: 768px) 768px,
        (max-width: 992px) 992px,
        (max-width: 1200px) 1200px,
        1515px"
      />
      <source
        type="image/jpeg"
        srcSet={
          `${images.jpg576} 576w,` +
          `${images.jpg768} 768w,` +
          `${images.jpg992} 992w,` +
          `${images.jpg1200} 1200w,` +
          `${images.jpg} 1515w`
        }
        sizes="(max-width: 576px) 576px,
        (max-width: 768px) 768px,
        (max-width: 992px) 992px,
        (max-width: 1200px) 1200px,
        1515px"
      />
      <img src={images.jpg} alt="" />
    </picture>
  );
};

export default OptimizedPicture;

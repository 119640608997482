import React from 'react';
import './FooterFollowUs.scss';
import Icon from '../icon/Icon';
import { IconName } from '../icon/IconName';
import {
  RENAISSANCE_CAPITAL_FACEBOOK_URL,
  RENAISSANCE_CAPITAL_TWITTER_URL,
  RENAISSANCE_CAPITAL_LINKEDIN_URL,
  RENAISSANCE_CAPITAL_YOUTUBE_URL,
} from '../../constants/urls';

const FooterFollowUs: React.FC = () => {
  return (
    <div className="FooterFollowUs">
      <div className="FooterFollowUs__label">Follow us</div>

      <a className="FooterFollowUs__link" href={RENAISSANCE_CAPITAL_FACEBOOK_URL}>
        <Icon name={IconName.Facebook} />
      </a>
      <a className="FooterFollowUs__link" href={RENAISSANCE_CAPITAL_TWITTER_URL}>
        <Icon name={IconName.Twitter} />
      </a>
      <a className="FooterFollowUs__link" href={RENAISSANCE_CAPITAL_LINKEDIN_URL}>
        <Icon name={IconName.LinkedIn} />
      </a>
      <a className="FooterFollowUs__link-youtube" href={RENAISSANCE_CAPITAL_YOUTUBE_URL}>
        <Icon name={IconName.Youtube} />
      </a>
    </div>
  );
};

export default FooterFollowUs;

import { Location } from 'history';
import { history } from '../history';
import { RoutePath } from '../enums/RoutePath';
import { store } from '../../store';
import { default as H, LocationDescriptorObject } from 'history';
import { LocationSearchData } from '../interfaces/LocationSearchData';
import { Url } from '../types';
import { ScrollService } from './ScrollService';
import { LocationQueryStringService } from './LocationQueryStringService';

export default class LocationService {
  static get origin(): string {
    return window.location.origin;
  }

  static getSearch(searchData?: LocationSearchData): string | undefined {
    return searchData && LocationQueryStringService.getQueryStringFromSearchData(searchData);
  }

  static getRelativePath(pathname: string, searchData?: LocationSearchData): string {
    const search = this.getSearch(searchData);
    const href = history.createHref({ pathname, search });

    return href[0] === '/' ? href : `/${href}`;
  }

  static getUrl(pathname: string, searchData?: LocationSearchData): Url {
    const path = this.getRelativePath(pathname, searchData);

    return `${this.origin}${path}`;
  }

  static redirectToLocation<S>(location: LocationDescriptorObject<S>, shouldReplace = false): void {
    if (shouldReplace) {
      history.replace(location);
    } else {
      history.push(location);

      // Have to scroll directly when navigate to the new page
      // https://github.com/ReactTraining/history/issues/710
      ScrollService.scrollToTop();
    }
  }

  static redirect(routePath: RoutePath, searchData?: LocationSearchData, shouldReplace = false): void {
    this.redirectToLocation(
      {
        pathname: routePath,
        search: this.getSearch(searchData),
      },
      shouldReplace,
    );
  }

  static redirectExternalLink(url: string, self?: boolean): void {
    window.open(url, `${self ? '_self' : ''}`);
  }

  static updateQueryParams(searchData?: LocationSearchData, shouldReplace = false): void {
    this.redirect(history.location.pathname as RoutePath, searchData, shouldReplace);
  }

  static isCurrentPath(routePath: RoutePath): boolean {
    return history.location.pathname === routePath;
  }

  static hasCurrentPath(routePaths: RoutePath[], currentLocation: H.Location): boolean {
    return routePaths.includes(currentLocation.pathname as RoutePath);
  }

  static get currentPath(): RoutePath {
    return history.location.pathname as RoutePath;
  }

  static navigateNotFound(): void {
    this.redirect(RoutePath.NotFound);
  }

  static reloadPage(): void {
    history.go(0);
  }

  static isSameLocation(locationA: Location, locationB: Location): boolean {
    const hrefA = history.createHref(locationA);
    const hrefB = history.createHref(locationB);

    return hrefA === hrefB;
  }

  static reloadPageIfSameLocation(locationA: Location, locationB: Location): void {
    if (LocationService.isSameLocation(locationA, locationB)) {
      LocationService.reloadPage();
    }
  }
}

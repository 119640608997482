import React from 'react';
import './ReducedSizeText.scss';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';
import { SizeTextProps } from './SizeText';

const ReducedSizeText: React.FC<SizeTextProps> = props => {
  const { size = ControlSize.Medium, className } = props;

  const cn = useClassName('ReducedSizeText', className);

  return <div className={cn({ [size]: size })}>{props.children}</div>;
};

export default ReducedSizeText;

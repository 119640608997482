import {
  CLOSE_MOBILE_MENU,
  CloseMobileMenuAction,
  SET_ADDITIONAL_MENU_VISIBILITY,
  SetAdditionalMenuVisibility,
  TOGGLE_MOBILE_MENU,
  ToggleMobileMenuAction,
} from './types';

export function toggleMobileMenu(): ToggleMobileMenuAction {
  return {
    type: TOGGLE_MOBILE_MENU,
    payload: {},
  };
}

export function closeMobileMenu(): CloseMobileMenuAction {
  return {
    type: CLOSE_MOBILE_MENU,
    payload: {},
  };
}

export function setAdditionalMenuVisibility(isAdditionalMenuVisible: boolean): SetAdditionalMenuVisibility {
  return {
    type: SET_ADDITIONAL_MENU_VISIBILITY,
    payload: { isAdditionalMenuVisible },
  };
}

import { Notification } from '../../shared/components/notification/interfaces/Notification';
import { NotificationAdd } from '../../shared/components/notification/interfaces/NotificationAdd';
import { RandomizeService } from '../../shared/services/RandomizeService';
import { UUID } from '../../shared/types';

export const addNotification = (notification: NotificationAdd, notifications: Notification[]): Notification[] => [
  ...notifications,
  {
    ...notification,
    id: RandomizeService.generateUuid(),
  },
];
export const removeNotification = (id: UUID, notifications: Notification[]): Notification[] =>
  notifications.filter(notification => notification.id !== id);

import React from 'react';
import './TextContainer.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const TextContainer: React.FC<Props> = props => (
  <div className={classNames('TextContainer', props.className)}>{props.children}</div>
);

export default TextContainer;

import React from 'react';
import { RoutePath } from '../enums/RoutePath';
import PrimarySection from './primary-section/PrimarySection';
import DocumentHead from './DocumentHead';

const NotFound: React.FC = () => {
  return (
    <section className="NotFound">
      <DocumentHead path={RoutePath.NotFound} />

      <PrimarySection>
        <h1>Not Found</h1>
      </PrimarySection>
    </section>
  );
};

export default NotFound;

import { createContext } from 'react';
import { Url } from '../../types';

export interface LightBoxContextValue {
  index: number;
  images: Url[];

  setImages(images: Url[]): void;
  setIndex(index: number): void;
}

class LightBoxContextDefaults implements LightBoxContextValue {
  index = 0;
  images: Url[] = [];

  setImages(images: Url[]): void {
    this.images = images;
  }

  setIndex(index: number): void {
    this.index = index;
  }
}

export const LightBoxContext = createContext<LightBoxContextValue>(new LightBoxContextDefaults());

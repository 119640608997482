import { RoutePath } from '../enums/RoutePath';

export type DocumentHeadRoutePath =
  | RoutePath.Home
  | RoutePath.Research
  | RoutePath.Features
  | RoutePath.WhoUsesUs
  | RoutePath.Pricing
  | RoutePath.About
  | RoutePath.UserPrivacy
  | RoutePath.NotFound;

export interface DocumentHeadData {
  title: string;
  description: string;
}

type DocumentHead = {
  [key in DocumentHeadRoutePath]: DocumentHeadData;
};

export const DOCUMENT_HEAD: DocumentHead = {
  [RoutePath.Home]: {
    title: 'Pre-IPO Research Service',
    description:
      'Pre-IPO Institutional Research Service offering IPO ratings, ' +
      'IPO Reports, IPO Alerts, DCF Models and IPO Insights',
  },

  [RoutePath.Research]: {
    title: 'Pre-IPO Research Reports & Ratings',
    description: 'Pre-IPO Institutional Research Reports, IPO Ratings, DCF Models, and Opinions',
  },

  [RoutePath.Features]: {
    title: 'IPO Alerts, Models & Screening',
    description: 'Pre-IPO Reports with Investment Ratings, Financial Models, IPO Email Alerts, IPO Screening',
  },

  [RoutePath.WhoUsesUs]: {
    title: 'Our Clients',
    description:
      'Pre-IPO Research Platform used by Hedge Funds, Investment Banks, Asset Managers and VC/Private Equity Firms',
  },

  [RoutePath.Pricing]: {
    title: 'Pricing',
    description: 'Valuable Pre-IPO Research, IPO Insights, IPO Ratings, IPO Calendars, IPO News and Commentaries',
  },

  [RoutePath.About]: {
    title: 'Pre-IPO Research',
    description:
      'We provide pre-IPO institutional research with our IPO Intelligence paltform, ' +
      'IPO-focused investment products and IPO Pro - an online portal for retail investors. ' +
      'Our mission is to help investors uncover and profit from IPOs. - Renaissance Capital',
  },

  [RoutePath.UserPrivacy]: {
    title: 'User Privacy',
    description: 'Pre-IPO User Privacy',
  },

  [RoutePath.NotFound]: {
    title: 'Not found (404)',
    description: '',
  },
};

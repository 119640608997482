import { OptimizedPictureType } from '../enums/OptimizedPictureType';

export const optimizedPicturesLinks = {
  [OptimizedPictureType.OverviewMeeting]: {
    webp576: require('../images/overview-meeting/image-576.webp'),
    webp768: require('../images/overview-meeting/image-768.webp'),
    webp992: require('../images/overview-meeting/image-992.webp'),
    webp1200: require('../images/overview-meeting/image-1200.webp'),
    webp: require('../images/overview-meeting/image.webp'),
    jpg576: require('../images/overview-meeting/image-576.jpg'),
    jpg768: require('../images/overview-meeting/image-768.jpg'),
    jpg992: require('../images/overview-meeting/image-992.jpg'),
    jpg1200: require('../images/overview-meeting/image-1200.jpg'),
    jpg: require('../images/overview-meeting/image.jpg'),
  },
  [OptimizedPictureType.ResearchMeeting]: {
    webp576: require('../images/research-meeting/image-576.webp'),
    webp768: require('../images/research-meeting/image-768.webp'),
    webp992: require('../images/research-meeting/image-992.webp'),
    webp1200: require('../images/research-meeting/image-1200.webp'),
    webp: require('../images/research-meeting/image.webp'),
    jpg576: require('../images/research-meeting/image-576.jpg'),
    jpg768: require('../images/research-meeting/image-768.jpg'),
    jpg992: require('../images/research-meeting/image-992.jpg'),
    jpg1200: require('../images/research-meeting/image-1200.jpg'),
    jpg: require('../images/research-meeting/image.jpg'),
  },
  [OptimizedPictureType.FeaturesBuilding]: {
    webp576: require('../images/features-buildings/image-576.webp'),
    webp768: require('../images/features-buildings/image-768.webp'),
    webp992: require('../images/features-buildings/image-992.webp'),
    webp1200: require('../images/features-buildings/image-1200.webp'),
    webp: require('../images/features-buildings/image.webp'),
    jpg576: require('../images/features-buildings/image-576.jpg'),
    jpg768: require('../images/features-buildings/image-768.jpg'),
    jpg992: require('../images/features-buildings/image-992.jpg'),
    jpg1200: require('../images/features-buildings/image-1200.jpg'),
    jpg: require('../images/features-buildings/image.jpg'),
  },
  [OptimizedPictureType.WhoUsesUsBusinessmen]: {
    webp576: require('../images/who-uses-us-businessmen/image-576.webp'),
    webp768: require('../images/who-uses-us-businessmen/image-768.webp'),
    webp992: require('../images/who-uses-us-businessmen/image-992.webp'),
    webp1200: require('../images/who-uses-us-businessmen/image-1200.webp'),
    webp: require('../images/who-uses-us-businessmen/image.webp'),
    jpg576: require('../images/who-uses-us-businessmen/image-576.jpg'),
    jpg768: require('../images/who-uses-us-businessmen/image-768.jpg'),
    jpg992: require('../images/who-uses-us-businessmen/image-992.jpg'),
    jpg1200: require('../images/who-uses-us-businessmen/image-1200.jpg'),
    jpg: require('../images/who-uses-us-businessmen/image.jpg'),
  },
  [OptimizedPictureType.AboutDoorLogo]: {
    webp576: require('../images/about-door-logo/image-576.webp'),
    webp768: require('../images/about-door-logo/image-768.webp'),
    webp992: require('../images/about-door-logo/image-992.webp'),
    webp1200: require('../images/about-door-logo/image-1200.webp'),
    webp: require('../images/about-door-logo/image.webp'),
    jpg576: require('../images/about-door-logo/image-576.jpg'),
    jpg768: require('../images/about-door-logo/image-768.jpg'),
    jpg992: require('../images/about-door-logo/image-992.jpg'),
    jpg1200: require('../images/about-door-logo/image-1200.jpg'),
    jpg: require('../images/about-door-logo/image.jpg'),
  },
};

import React from 'react';
import Feature from '../shared/Feature';
import SubheaderQuote from '../shared/SubheaderQuote';
import RequestTrial from '../shared/RequestTrial';
import Video from '../shared/Video';
import ScreenshotDescription from '../shared/ScreenshotDescription';
import { researchScreenshotDescriptionProps } from './researchScreenshotDescriptionProps';
import MarketingPerformance from '../shared/performance/MarketingPerformance';
import ResearchProcess from './ResearchProcess';
import ResearchIpoLifecycle from './ResearchIpoLifecycle';
import ResearchFlow from './flow/ResearchFlow';
import ResearchProcessHeader from './ResearchProcessHeader';
import { coverageFlowProps, researchFlowProps } from './flow/flowProps';
import DocumentHead from '../../shared/components/DocumentHead';
import { OptimizedPictureType } from '../shared/optimized-picture/enums/OptimizedPictureType';
import { RoutePath } from '../../shared/enums/RoutePath';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import { ColorScheme } from '../../shared/enums/ColorScheme';
import { PrimarySectionIndent } from '../../shared/components/primary-section/PrimarySectionIndent';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';

const Research: React.FC = () => {
  // <article className="Research">
  // <DocumentHead path={RoutePath.Research} />
  // Now showing Research component on Features' route
  return (
    <article className="Features">
      <DocumentHead path={RoutePath.Features} />

      <Feature
        header={
          <span>
            Obtain actionable,
            <span className="d-lg-block d-xl-block" /> independent ipo insights
          </span>
        }
        optimizedPictureType={OptimizedPictureType.ResearchMeeting}
      >
        Renaissance Capital’s IPO Intelligence research platform brings you comprehensive, unbiased pre-IPO
        <span className="d-lg-block d-xl-block" /> research and models on U.S. and international IPOs, so you can
        separate the good IPOs from the bad.
      </Feature>

      <PrimarySection colorScheme={ColorScheme.Primary} indent={PrimarySectionIndent.Internal}>
        <SubheaderQuote>
          IPO Intelligence is your objective, comprehensive way to identify winning IPOs - brought to you by Renaissance
          Capital LLC
        </SubheaderQuote>
      </PrimarySection>

      <PrimarySection colorScheme={ColorScheme.Gray}>
        <ScreenshotDescription {...researchScreenshotDescriptionProps} isVideo={true} />
      </PrimarySection>

      <PrimarySection header={<ResearchProcessHeader />}>
        <PrimarySectionRow>
          <ResearchProcess />
        </PrimarySectionRow>

        <PrimarySectionRow>
          <ResearchFlow title="Full IPO Lifecycle Research" items={researchFlowProps} />

          {/* <ResearchFlow title="Complete Market Coverage" items={coverageFlowProps} /> */}
        </PrimarySectionRow>

        <PrimarySectionRow>
          <ResearchIpoLifecycle />
        </PrimarySectionRow>
      </PrimarySection>

      {/* <PrimarySection colorScheme={ColorScheme.Gray}>
        <MarketingPerformance />
      </PrimarySection> */}

      <RequestTrial title="Request your free trial now" button="Sign up today" />

      {/* <PrimarySection colorScheme={ColorScheme.Gray}>
        <Video
          title="Walk through the IPO intelligence platform to see exactly what you'll get"
          youTubeVideoId="WFP8E_e4er8"
        />
      </PrimarySection> */}
    </article>
  );
};

export default Research;

import CarouselArrow from './CarouselArrow';
import { IconName } from '../icon/IconName';
import { BreakpointSizes } from '../../enums/BreakpointSizes';
import React from 'react';
import { Settings } from 'react-slick';

export const settings: Settings = {
  dots: false,
  infinite: true,
  arrows: true,
  swipeToSlide: true,
  slidesToScroll: 1,
  slidesToShow: 4,
  touchThreshold: 15,
  prevArrow: <CarouselArrow icon={IconName.LeftArrow} />,
  nextArrow: <CarouselArrow icon={IconName.RightArrow} />,
  responsive: [
    {
      breakpoint: BreakpointSizes.Large,
      settings: {
        slidesToShow: 4,
        touchThreshold: 12,
      },
    },
    {
      breakpoint: BreakpointSizes.Medium,
      settings: {
        slidesToShow: 3,
        touchThreshold: 9,
      },
    },
    {
      breakpoint: BreakpointSizes.Small,
      settings: {
        slidesToShow: 2,
        touchThreshold: 6,
      },
    },
    {
      breakpoint: 460,
      settings: {
        slidesToShow: 1,
        touchThreshold: 3,
      },
    },
  ],
};

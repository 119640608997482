import Highcharts from 'highcharts';

export const verticalColumnChartOptions: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  yAxis: {
    stackLabels: {
      enabled: true,
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
    column: {
      stacking: 'normal',
    },
  },
};

import React from 'react';
import Helmet from 'react-helmet';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import PrimarySectionRow from '../shared/components/primary-section/PrimarySectionRow';

const Contact: React.FC = () => {
  return (
    <div className="Contact">
      <Helmet>
        <title>Important Disclosures &mdash; Renaissance Capital</title>
      </Helmet>

      <PrimarySection header="Important Disclosures">
        <PrimarySectionRow>
          <div>
            <div>
              The{' '}
              <a href={'/Docs/ADVPartIIDisclosureBrochure.pdf'} target="_blank" rel="noopener noreferrer">
                ADV Part II Disclosure Brochure
              </a>{' '}
              provides information about the advisory qualifications and business practices of Renaissance Capital LLC.
            </div>

            <div>
              Renaissance Capital&apos;s{' '}
              <a href={'/Docs/PrivacyPolicy.pdf'} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>{' '}
              describes how we protect your personal information.
            </div>

            <div>
              Renaissance Capital&apos;s{' '}
              <a href={'/Docs/GDPRDataProtectionPolicy.pdf'} target="_blank" rel="noopener noreferrer">
                GDPR Data Protection Policy
              </a>{' '}
              summarizes our collection, use and control of the personal data of residents of the European Economic
              Area.
            </div>

            <div>
              Renaissance Capital&apos;s{' '}
              <a href={'/Docs/AnitMoneyLaunderingPolicy.pdf'} target="_blank" rel="noopener noreferrer">
                Anti-Money Laundering Policy
              </a>{' '}
              summarizes how we detect and prevent money laundering.
            </div>

            <div>
              Renaissance Capital&apos;s{' '}
              <a href={'/Docs/BusinessContinuityPlan.pdf'} target="_blank" rel="noopener noreferrer">
                Business Continuity Plan
              </a>{' '}
              outlines how we respond to business disruptions.
            </div>
          </div>
        </PrimarySectionRow>
      </PrimarySection>
    </div>
  );
};

export default Contact;

export enum PrimarySectionBlock {
  Root = 'PrimarySection',
}

export enum PrimarySectionElement {
  Container = 'container',
  Header = 'header',
  Content = 'content',
}

export enum PrimarySectionModifier {
  MaxContainerSize = 'maxContainerSize',
  Underlined = 'underlined',
  ExtraPadding = 'extraPadding',
}

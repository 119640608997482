import { AboutUnitProps } from './ipoUnits/AboutUnit';

export const unitProps: AboutUnitProps[] = [
  {
    title: 'IPO ETFs',
    description:
      'Thinking of investing in new companies ' +
      'before they become household names? ' +
      'Our IPO focused ETFs for the U.S. ' +
      'market (NYSE: IPO) and international ' +
      'markets (NYSE: IPOS) help you access ' +
      'companies like these early, while minimizing ' +
      'the risk of single stock selection and ' +
      'adding diversification to your portfolio.',
    link: 'https://www.renaissancecapital.com/IPO-Investing',
  },
  {
    title: 'IPO Intelligence',
    description:
      'We help the world’s largest and most active ' +
      'institutional investors, investment banks and ' +
      'IPO advisory firms track, analyze and gain ' +
      'actionable insights into the global IPO ' +
      'market. Our IPO Intelligence research ' +
      'platform is the gold standard for independent ' +
      'pre-IPO research.',
    link: 'https://www.ipointelligence.com/features',
  },
  {
    title: 'IPO Pro',
    description:
      'Everything you need to know about the IPO ' +
      'market in one platform. Access premium ' +
      'features like the IPO pipeline, specific trade ' +
      'dates, IPO profiles, preliminary data ' +
      'screening and valuation metrics to stay ahead of ' +
      'the IPO market.',
    link: 'https://ipopro.renaissancecapital.com/',
  },
  {
    title: 'IPO Data',
    description:
      'We license IPO-focused data feeds through ' +
      'an API to financial institutions. We also ' +
      'license our Renaissance Capital IPO Index ' +
      '(IPOUSA) and our Renaissance Capital ' +
      'International IPO Index (IPOXUS).',
    link: 'https://www.renaissancecapital.com/Contact?Category=Data-Feeds',
  },
  {
    title: 'IPO Center',
    description:
      'Access and utilize the leading source of ' +
      'up-to-date news and information on the IPO ' +
      'market. This free site keeps you informed ' +
      'about upcoming IPOs, helps you analyze ' +
      'them, tracks returns and follows IPO market ' +
      'trends.',
    link: 'https://www.renaissancecapital.com/IPO-Center',
  },
];

import { PORTAL_SITE_URL } from './environment';

export const RENAISSANCE_CAPITAL_WEBSITE = 'https://www.renaissancecapital.com';
export const NEWSLETTER_URL = 'https://ipoupdates.renaissancecapital.com/';
export const ABOUT_RESEARCH_URL = 'https://www.renaissancecapital.com/Docs/About-Research.pdf';
export const RENAISSANCE_CAPITAL_FACEBOOK_URL = 'https://web.facebook.com/RenaissanceCapitalIPO/?_rdc=1&_rdr';
export const RENAISSANCE_CAPITAL_TWITTER_URL = 'https://twitter.com/IPOtweet';
export const RENAISSANCE_CAPITAL_LINKEDIN_URL = 'https://www.linkedin.com/company/renaissance-capital_3';
export const RENAISSANCE_CAPITAL_YOUTUBE_URL = 'https://www.youtube.com/@RenaissanceCapitalIPO';
export const IMPORTANT_DISCLOSURES_URL = 'https://ipopro.renaissancecapital.com/Disclosures';
export const IPO_INTELLIGENCE_CLIENT_SITE_LOGIN_URL = `${PORTAL_SITE_URL}/login`;
export const IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US = `${PORTAL_SITE_URL}/contact`;
export const IPO_INTELLIGENCE_CLIENT_SITE_FREE_TRIAL = `${PORTAL_SITE_URL}/request`;

import React, { useCallback } from 'react';
import './MainMenuList.scss';
import { RootState } from '../../../store';
import { closeMobileMenu } from '../../../store/header/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RoutePath } from '../../enums/RoutePath';
import { IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US, NEWSLETTER_URL } from '../../constants/urls';
import { Link, NavLink } from 'react-router-dom';

const MainMenuList: React.FC = () => {
  const dispatch = useDispatch();
  const open = useSelector<RootState, boolean>(state => state.header.isMobileMenuOpen);

  const onLinkClick = useCallback((): void => {
    if (open) {
      dispatch(closeMobileMenu());
    }
  }, [dispatch, open]);

  return (
    <ul className="MainMenuList">
      <li className="MainMenuList__item">
        <NavLink
          activeClassName={'active'}
          className="MainMenuList__link"
          to={RoutePath.Overview}
          exact
          onClick={onLinkClick}
        >
          Overview
        </NavLink>
      </li>
      {/* <li className="MainMenuList__item">
        <NavLink
          activeClassName={'active'}
          className="MainMenuList__link"
          to={RoutePath.Research}
          onClick={onLinkClick}
        >
          Research
        </NavLink>
      </li>
      <li className="MainMenuList__item">
        <NavLink
          activeClassName={'active'}
          className="MainMenuList__link"
          to={RoutePath.Features}
          onClick={onLinkClick}
        >
          Features
        </NavLink>
      </li> */}
      <li className="MainMenuList__item">
        <NavLink
          activeClassName={'active'}
          className="MainMenuList__link"
          to={RoutePath.Features}
          onClick={onLinkClick}
        >
          Features
        </NavLink>
      </li>
      <li className="MainMenuList__item">
        <NavLink
          activeClassName={'active'}
          className="MainMenuList__link"
          to={RoutePath.WhoUsesUs}
          onClick={onLinkClick}
        >
          Who Uses Us
        </NavLink>
      </li>
      <li className="MainMenuList__item">
        <NavLink activeClassName={'active'} className="MainMenuList__link" to={RoutePath.Pricing} onClick={onLinkClick}>
          Pricing
        </NavLink>
      </li>
      <li className="MainMenuList__item">
        <a
          className="MainMenuList__link"
          href={NEWSLETTER_URL}
          target="_blank"
          rel="nofollow noopener noreferrer"
          onClick={onLinkClick}
        >
          Weekly IPO Updates
        </a>
      </li>
      <li className="MainMenuList__item">
        <NavLink activeClassName={'active'} className="MainMenuList__link" to={RoutePath.About} onClick={onLinkClick}>
          About
        </NavLink>
      </li>
      <li className="MainMenuList__item MainMenuList__item--divider" />
      <li className="MainMenuList__item MainMenuList__item--hamburger">
        <a
          className="MainMenuList__link"
          href={IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US}
          rel="nofollow noopener noreferrer"
        >
          Contact Us &gt;
        </a>
        {/* <NavLink className="MainMenuList__link" to={RoutePath.About} onClick={onLinkClick}>
          Contact Usss &gt;
        </NavLink> */}
      </li>
    </ul>
  );
};

export default MainMenuList;

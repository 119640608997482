import { useEffect, useState } from 'react';
import { Location } from 'history';
import { history } from '../history';

export const useHistoryChange = (callback: (prevLocation: Location, location: Location) => void): void => {
  const [prevLocation, setPrevLocation] = useState(history.location);

  useEffect(() => {
    const unlisten = history.listen((location): void => {
      setPrevLocation(location);

      callback(prevLocation, location);
    });

    return (): void => {
      unlisten();
    };
  }, [callback, prevLocation]);
};

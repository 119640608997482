const itemsList: string[] = [
  'Independent: Balanced, not just a Wall Street pitch.',
  'Comprehensive: Covers 100% of the U.S. IPOs and major international deals.',
  'Valuable: One smart institutional trade can pay for the subscription fee.',
  'Cost Effective: The service costs a fraction of hiring an analyst.',
  'Timely: Issued before the IPO is priced, when other coverage is not available.',
  'Smart: Enviable track record of distinguishing the good IPOs from the bad.',
  'Disciplined: Uses a time-tested framework for successfully evaluating IPOs.',
];

export default itemsList;

import { CustomAny } from '../../../types/generics';
import { HtmlString } from '../../../types/HtmlString';
import { ChartValuesType } from '../enums/ChartValuesType';
import { NumberHelper } from '../../../helpers/NumberHelper';
import { NumberFormat } from '../../../enums/NumberFormat';
import { isArray } from 'lodash/fp';
import { ChartTooltipOptions } from '../interfaces/ChartTooltipOptions';

export class ChartTooltipFormatterService {
  static getTooltip(options: ChartTooltipOptions): HtmlString {
    const pointsView = this.getPoints(options.points, options.pointsValueType, options.formatter);

    return this.getRows([this.getHeader(options.title), ...pointsView]);
  }

  private static getPointValue(point: CustomAny, pointValueType?: ChartValuesType) {
    switch (pointValueType) {
      case ChartValuesType.Percentage:
        return NumberHelper.getFormatValue(point.y, NumberFormat.PercentageInteger);
      case ChartValuesType.Currency:
        return NumberHelper.getFormatValue(point.y, NumberFormat.CurrencyFloatWithLetter);
      case ChartValuesType.Float:
        return NumberHelper.getFormatValue(point.y, NumberFormat.Float);
      case ChartValuesType.Integer:
        return NumberHelper.getFormatValue(point.y, NumberFormat.WithComma);
      case ChartValuesType.PercentageFloat:
        return NumberHelper.getFormatValue(point.y, NumberFormat.PercentageFloat);
      default:
        return point.y;
    }
  }

  private static getPoint(
    point: CustomAny,
    pointValueType?: ChartValuesType,
    formatter?: (value: number) => string,
  ): HtmlString {
    let value = this.getPointValue(point, pointValueType);

    if (formatter) {
      value = formatter(point.y);
    }

    return `<div><span class="highcharts-color-${point.colorIndex}">● </span>${point.series.name}: <b>${value}</b></div>`;
  }

  private static getPoints(
    points: CustomAny[],
    pointsValueType?: ChartValuesType | ChartValuesType[],
    formatter?: (value: number) => string,
  ): HtmlString[] {
    return points.map((point: CustomAny) =>
      this.getPoint(point, pointsValueType && this.getPointValueType(pointsValueType, point.colorIndex), formatter),
    );
  }

  private static getPointValueType(
    pointsValueType: ChartValuesType | ChartValuesType[],
    index: number,
  ): ChartValuesType {
    if (isArray(pointsValueType)) {
      return pointsValueType?.[index] as ChartValuesType;
    }

    return pointsValueType as ChartValuesType;
  }

  private static getHeader(text: string): HtmlString {
    return `<div class="highcharts-header">${text}</div>`;
  }

  private static getRows(tooltipRows: string[]): HtmlString {
    return tooltipRows.join('<br/>');
  }
}

import React from 'react';
import Icon from '../icon/Icon';
import { IconName } from '../icon/IconName';
import classNames from 'classnames';
import { CustomArrowProps } from 'react-slick';
import './CarouselArrow.scss';

interface Props extends CustomArrowProps {
  icon: IconName;
}

const CarouselArrow: React.FC<Props> = props => {
  return (
    <button className={classNames('CarouselArrow', props.className)} onClick={props.onClick}>
      <Icon name={props.icon} />
    </button>
  );
};

export default CarouselArrow;

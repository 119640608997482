import { HtmlString } from '../types/HtmlString';

export default function convertHTML(str: HtmlString) {
  let modifiedTxt = '';
  if (!str || str.length === 0) {
    return modifiedTxt;
  }
  let i;
  for (i = 0; i < str.length - 1; i++) {
    const nextChar = str.charAt(i + 1);
    // /\d/.test(nextChar) checks if the argument contains a number
    if (str.charAt(i) === '<' && (nextChar === ' ' || nextChar === '$' || /\d/.test(nextChar))) {
      modifiedTxt += '&lt;';
    } else {
      modifiedTxt += str.charAt(i);
    }
  }

  modifiedTxt += str.charAt(i);
  return modifiedTxt;
}

import React from 'react';
import './OrderedItem.scss';
import { OrderedListItem } from './interfaces/OrderedListItem';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';
import ReducedSizeText from '../typography/ReducedSizeText';
import SizeText from '../typography/SizeText';

interface Props {
  item: OrderedListItem;
  size: ControlSize;
  isTitleHighlighted: boolean;
}

const OrderedItem: React.FC<Props> = props => {
  const { title, description } = props.item;

  const cn = useClassName('OrderedItem');

  return (
    <li
      className={cn({
        [props.size]: props.size,
        titleHighlighted: props.isTitleHighlighted,
      })}
    >
      {title && (
        <SizeText className={cn('title')} size={props.size}>
          {title}
        </SizeText>
      )}

      {description && (
        <ReducedSizeText className="OrderedItem__description" size={props.size}>
          {description}
        </ReducedSizeText>
      )}
    </li>
  );
};

export default OrderedItem;

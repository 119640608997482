import { EffectCallback } from 'react';

export default function escEffect(callback: () => void, use = true): EffectCallback {
  function onKeyDown(event: KeyboardEvent): void {
    if (['Escape', 'Esc'].includes(event.key)) {
      callback();
    }
  }

  return (): void | (() => void) => {
    if (use) {
      document.addEventListener('keydown', onKeyDown);

      return (): void => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }
  };
}

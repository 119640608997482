import React from 'react';
import AnnualRatingsPerformanceChart from '../../../shared/entities/chart/AnnualRatingsPerformanceChart';
import TextContainer from '../TextContainer';
import './MarketingPerformance.scss';

const MarketingPerformance: React.FC = () => {
  return (
    <div className="MarketingPerformance">
      <div className="MarketingPerformance__title">
        Distinguishing good IPO<span className="text-lowercase">s</span> from bad
      </div>

      <div className="MarketingPerformance__chart">
        <AnnualRatingsPerformanceChart />
      </div>

      <TextContainer>
        <p>
          Our team of financial analysts evaluate and rate every institutional IPO. We make it easy for you to
          distinguish the good from the bad, and give you the insights you need to support your investment decisions.
        </p>
        <p>
          After over 30 years of evaluating IPOs, we know the right way to look at a deal, and our ratings track record
          proves it. IPO Intelligence is the gold standard in IPO research.
        </p>
      </TextContainer>
    </div>
  );
};

export default MarketingPerformance;

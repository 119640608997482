import React from 'react';
import Feature from '../shared/Feature';
import OverviewPerformance from './OverviewPerformance';
import RequestTrial from '../shared/RequestTrial';
import Video from '../shared/Video';
import QuotesItem from '../shared/quotes/QuotesItem';
import Quotes from '../shared/quotes/Quotes';
import HeaderLine from '../shared/HeaderLine';
import MultipleQuotes from '../shared/MultipleQuotes';
import OverviewFeatures from './features/OverviewFeatures';
import OverviewFeatured from './featured/OverviewFeatured';
import DocumentHead from '../../shared/components/DocumentHead';
import CarouselWithAllClients from '../shared/CarouselWithAllClients';
import { OptimizedPictureType } from '../shared/optimized-picture/enums/OptimizedPictureType';
import { RoutePath } from '../../shared/enums/RoutePath';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import { ColorScheme } from '../../shared/enums/ColorScheme';
import { PrimarySectionIndent } from '../../shared/components/primary-section/PrimarySectionIndent';

const Overview: React.FC = () => {
  return (
    <div className="Overview">
      <DocumentHead path={RoutePath.Home} />

      <Feature
        header={
          <>
            Evaluate deals with independent
            <span className="d-lg-block d-xl-block" /> pre-IPO research
          </>
        }
        optimizedPictureType={OptimizedPictureType.OverviewMeeting}
      >
        Renaissance Capital’s IPO Intelligence research platform brings you comprehensive, unbiased pre-IPO
        <span className="d-lg-block d-xl-block" /> research and models on U.S. and international IPOs, so you can
        separate the good IPOs from the bad.
      </Feature>

      <PrimarySection>
        <OverviewPerformance />
      </PrimarySection>

      {/* <PrimarySection colorScheme={ColorScheme.Gray}>
        <Video
          title="IPO intelligence delivers the timely, reliable IPO knowledge you need to make&nbsp;smart investments"
          youTubeVideoId="WFP8E_e4er8"
        />
      </PrimarySection> */}

      <PrimarySection>
        <HeaderLine>Trusted by the smartest investors and deal makers</HeaderLine>
      </PrimarySection>

      <PrimarySection indent={PrimarySectionIndent.None}>
        <CarouselWithAllClients />
      </PrimarySection>

      <PrimarySection>
        <Quotes>
          <QuotesItem quote="They know how institutional investors look at deals." author="Alternative Asset Manager" />
          <QuotesItem
            quote="Their IPO research has been an integral part of our investment process for over 15 years."
            author="$200B+ Asset Manager"
          />
        </Quotes>
      </PrimarySection>

      <PrimarySection colorScheme={ColorScheme.Gray} indent={PrimarySectionIndent.Internal}>
        <HeaderLine>Why IPO intelligence?</HeaderLine>
      </PrimarySection>

      <OverviewFeatures />

      <RequestTrial title="Request your free trial now" button="Sign up today" />

      <PrimarySection>
        <MultipleQuotes />

        <OverviewFeatured />
      </PrimarySection>
    </div>
  );
};

export default Overview;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import './Carousel.scss';
import { settings } from './settings';

const Carousel: React.FC = props => {
  return (
    <div className="Carousel">
      <Slider {...settings}>{props.children}</Slider>
    </div>
  );
};

export default Carousel;

import { IconOptions } from '../interfaces/IconOptions';
import { IconProps } from '../Icon';
import { IconName } from '../IconName';
import { ColorScheme } from '../../../enums/ColorScheme';

export class IconFactory {
  static get(props: IconProps): IconOptions {
    let options: IconOptions = {};

    switch (props.name) {
      case IconName.Excel:
        options = {
          colorScheme: ColorScheme.Success,
        };
        break;
      case IconName.Pdf:
        options = {
          colorScheme: ColorScheme.Danger,
        };
        break;
    }

    return {
      colorScheme: props.colorScheme || options.colorScheme,
    };
  }
}

import React from 'react';
import { HtmlString } from '../types/HtmlString';
import reactHtmlParse from 'react-html-parser';
import convertHtml from '../helpers/convertHtml';
import { HtmlStringFormatterBlock } from './htmlStringFormatterBem';
import { useClassName } from '../hooks/useClassName';
import './HtmlStringFormatter.scss';

interface Props {
  content: HtmlString;
}

const HtmlStringFormatter: React.FC<Props> = props => {
  const cn = useClassName(HtmlStringFormatterBlock.Root);

  return <div className={cn()}>{reactHtmlParse(convertHtml(props.content))}</div>;
};

export default HtmlStringFormatter;

import numeral from 'numeral';
import { isFinite, isString } from 'lodash';
import { NumberFormat } from '../enums/NumberFormat';

export class NumberHelper {
  static getFormatValue(value: number | undefined | null, format: NumberFormat): string {
    //Capitalize to show number postfix always in the upper case
    return numeral(value)
      .format(format)
      .toUpperCase();
  }

  static getInBillions(value: number): string {
    return this.getFormatValue(value / 1000000000, NumberFormat.WithComma);
  }

  static getFormatByType(value: string | number, format: NumberFormat): string {
    let valueToFormat = value;

    if (isString(value)) {
      valueToFormat = parseInt(value);

      // If it's not a number after parse
      // Then return value as is
      if (!isFinite(valueToFormat)) {
        return value;
      }
    }

    return this.getFormatValue(valueToFormat as number, format);
  }

  static percentageToNumber(value: number): number {
    return value / 100;
  }
}

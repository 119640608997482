import React from 'react';
import './MainMenu.scss';
import MainMenuList from './MainMenuList';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const MainMenu: React.FC = () => {
  const isMenuOpen = useSelector<RootState, boolean>(state => state.header.isMobileMenuOpen);

  return (
    <nav className="MainMenu">
      <div
        className={classNames('MainMenu__list', {
          'MainMenu__list--open': isMenuOpen,
        })}
      >
        <MainMenuList />
      </div>
    </nav>
  );
};

export default MainMenu;

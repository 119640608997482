import { SeriesOptionsType } from 'highcharts';
import { CustomAny } from '../../../types/generics';

export class ChartValueFormatters {
  static percentageValueFormatter(seriesOption: CustomAny): SeriesOptionsType {
    return {
      ...seriesOption,
      data: seriesOption.data.map((item: number) => Math.round(item * 100)),
    };
  }
}

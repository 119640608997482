import { Reducer } from 'redux';
import { ActionTypes, CLOSE_MOBILE_MENU, SET_ADDITIONAL_MENU_VISIBILITY, State, TOGGLE_MOBILE_MENU } from './types';

const initialState: State = {
  isMobileMenuOpen: false,
  isAdditionalMenuVisible: false,
};

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpen: !state.isMobileMenuOpen,
      };
    case CLOSE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpen: false,
      };
    case SET_ADDITIONAL_MENU_VISIBILITY:
      return {
        ...state,
        isAdditionalMenuVisible: action.payload.isAdditionalMenuVisible,
      };
    default:
      return state;
  }
};

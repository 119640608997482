const items: string[] = [
  'Pre-IPO Research Reports and Investment Ratings',
  'Access to the Web-Based IPO Research Portal',
  'Access for 6 users included',
  'IPO News and Market Commentaries',
  'Customizable Financial Models',
  'Proprietary Meeting Prep for 1-on-1s, Analyst Calls and Group Meetings',
  'Real Time IPO Email Alerts on Research and Pricings',
  'Screening of Historical IPO Data Sets and Pre-IPO Reports',
  'Proprietary Private Company Watchlist',
  'Consultations with our Team of IPO Experts',
  'IPO Calendars',
  'Special IPO Market Reports and Quarterly Reviews',
  'Client Only Morning Recap Emails',
];

export default items;

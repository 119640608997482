import React, { CSSProperties, useMemo } from 'react';
import './Icon.scss';
import { IconName } from './IconName';
import { ColorScheme } from '../../enums/ColorScheme';
import { ControlSize } from '../../enums/ControlSize';
import { IconBlock, IconModifier } from './IconBem';
import { useClassName } from '../../hooks/useClassName';
import { Nullable } from '../../types/generics';
import { IconFactory } from './factories/IconFactory';

export interface IconProps {
  name?: Nullable<IconName>;
  colorScheme?: ColorScheme;
  size?: ControlSize;
  className?: string;
  hasHover?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = props => {
  const cn = useClassName(IconBlock.Root, props.className);

  const options = useMemo(() => IconFactory.get(props), [props]);

  return (
    <>
      {props.name === IconName.Sheet ? (
        <>
          {' '}
          <img src={require('../../../marketing/shared/images/Sheet_Icon_IPOIntelligence.png')} alt="" />
        </>
      ) : (
        <svg
          className={cn({
            [`${props.name}`]: props.name,
            [`${options.colorScheme}`]: options.colorScheme,
            [`${props.size}`]: props.size,
            [IconModifier.Hover]: props.hasHover,
          })}
          style={props.style}
          onClick={props.onClick}
        >
          {props.name && <use href={require('./rencap-SVG-sprite.svg') + `#${props.name}`} />}
        </svg>
      )}
    </>
  );
};

export default Icon;

import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './HeaderLogo.scss';
import { useClassName } from '../../hooks/useClassName';
import { HeaderLogoBlock, HeaderLogoElement } from './HeaderLogoBem';
import { RoutePath } from '../../enums/RoutePath';

const HeaderLogo: FunctionComponent = () => {
  const cn = useClassName(HeaderLogoBlock.Root);

  return (
    <div className={cn()}>
      <Link className={cn(HeaderLogoElement.View)} to={RoutePath.Home} />

      <img className={cn(HeaderLogoElement.Print)} alt="" src={require('../../images/logo-header-orange.png')} />
    </div>
  );
};

export default HeaderLogo;

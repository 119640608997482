import React, { CSSProperties } from 'react';
import './LoaderContainer.scss';
import Loader from './Loader';
import OverlayContainer from '../overlay-container/OverlayContainer';
import { useClassName } from '../../hooks/useClassName';
import { LoaderContainerBlock, LoaderContainerModifier } from './loaderContainerBem';

interface Props {
  isBackgroundFill?: boolean;
  hasBackground?: boolean;
  content?: React.ReactNode;
  overlayContentStyle?: CSSProperties;
  isAbsolute?: boolean;
}

const LoaderContainer: React.FC<Props> = props => {
  const cn = useClassName(LoaderContainerBlock.Root);

  return (
    <div
      className={cn({
        [LoaderContainerModifier.Absolute]: props.isAbsolute,
      })}
    >
      {props.content}

      <OverlayContainer {...props} contentStyle={props.overlayContentStyle}>
        <Loader />
      </OverlayContainer>
    </div>
  );
};

export default LoaderContainer;

import React, { ReactNode } from 'react';
import './OrderedList.scss';
import { OrderedListItem } from './interfaces/OrderedListItem';
import OrderedItem from './OrderedItem';
import { useClassName } from '../../hooks/useClassName';
import { ControlSize } from '../../enums/ControlSize';
import SizeText from '../typography/SizeText';
import ReducedSizeText from '../typography/ReducedSizeText';

interface Props {
  items: OrderedListItem[];
  isItemTitleHighlighted?: boolean;
  isTitleSizeAsItem?: boolean;
  title?: ReactNode;
  size?: ControlSize;
  className?: string;
}

const OrderedList: React.FC<Props> = props => {
  const { size = ControlSize.Medium, isItemTitleHighlighted = false } = props;

  const cn = useClassName('OrderedList', props.className);

  return (
    <section
      className={cn({
        [size]: size,
      })}
    >
      {props.title &&
        (props.isTitleSizeAsItem ? (
          <ReducedSizeText className={cn('title')} size={props.size}>
            {props.title}
          </ReducedSizeText>
        ) : (
          <SizeText size={size} className={cn('title')}>
            {props.title}
          </SizeText>
        ))}

      <ol className={cn('list')}>
        {props.items.map((item: OrderedListItem, index: number) => (
          <OrderedItem key={index} item={item} size={size} isTitleHighlighted={isItemTitleHighlighted} />
        ))}
      </ol>
    </section>
  );
};

export default OrderedList;

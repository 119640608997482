import React, { FunctionComponent } from 'react';
import './HeaderLine.scss';

interface Props {
  content?: string;
}

const HeaderLine: FunctionComponent<Props> = props => {
  return <section className="HeaderLine">{props.children}</section>;
};

export default HeaderLine;

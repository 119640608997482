import Highcharts from 'highcharts';

export const percentageColumnChartOptions: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.y}%</b><br/>',
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        format: '{y}%',
      },
    },
  },
};

import { isEmpty, isEqual, pickBy, isNil } from 'lodash';
import { AnyObject, ObjectWithObjectChildren } from '../types/generics';

export class ObjectHelper {
  static arePropsEmpty(value: object): boolean {
    if (isEmpty(value)) {
      return true;
    }

    const newValue = pickBy(value);

    return isEmpty(newValue);
  }

  static getChildrenObjects(obj: ObjectWithObjectChildren): AnyObject {
    return Object.values(obj).reduce(
      (res: AnyObject, childObject: AnyObject) => ({
        ...res,
        ...childObject,
      }),
      {},
    );
  }

  static deleteEmptyProps(object: AnyObject): AnyObject {
    return pickBy(object, value => !isNil(value));
  }

  static isEqualNoEmpty(objectA: AnyObject, objectB: AnyObject): boolean {
    const newObjectA = this.deleteEmptyProps(objectA);
    const newObjectB = this.deleteEmptyProps(objectB);

    return isEqual(newObjectA, newObjectB);
  }
}

import React, { FunctionComponent, useCallback, useContext } from 'react';
import Lightbox from 'react-image-lightbox';
import { LightBoxContext } from './LightBoxContext';

import 'react-image-lightbox/style.css';

const LightBox: FunctionComponent = () => {
  const { images, setImages, index, setIndex } = useContext(LightBoxContext);

  const onCloseRequest = useCallback(() => setImages([]), [setImages]);
  const onMovePrevRequest = useCallback(() => setIndex((index + images.length - 1) % images.length), [
    setIndex,
    index,
    images,
  ]);
  const onMoveNextRequest = useCallback(() => setIndex((index + 1) % images.length), [setIndex, index, images]);

  return (
    <Lightbox
      mainSrc={images[index]}
      nextSrc={images[(index + 1) % images.length]}
      prevSrc={images[(index + images.length - 1) % images.length]}
      onCloseRequest={onCloseRequest}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
      // enableZoom={false}
    />
  );
};

export default LightBox;

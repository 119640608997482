import React, { useMemo } from 'react';
import './PageLoader.scss';
import Loader from './Loader';
import EmptyDataBlock from '../EmptyDataBlock';
import { CustomAny } from '../../types/generics';
import { ObjectHelper } from '../../helpers/ObjectHelper';
import LoaderContainer from './LoaderContainer';

interface Props {
  loading?: boolean;
  otherwise?: CustomAny;
  otherwiseContent?: React.ReactNode;
  isOverlay?: boolean;
}

const PageLoader: React.FC<Props> = props => {
  const { otherwise = true, loading, otherwiseContent, isOverlay } = props;

  const content = useMemo(
    () =>
      !otherwise && ObjectHelper.arePropsEmpty(otherwise) ? (
        <EmptyDataBlock content={otherwiseContent} />
      ) : (
        props.children
      ),
    [otherwise, otherwiseContent, props],
  );

  const simpleLoaderContent = useMemo(() => (loading ? <Loader className="PageLoader__loader" /> : content), [
    loading,
    content,
  ]);

  const overlayLoaderContent = useMemo(() => (loading ? <LoaderContainer content={props.children} /> : content), [
    loading,
    content,
    props.children,
  ]);

  return <>{isOverlay ? overlayLoaderContent : simpleLoaderContent}</>;
};

export default PageLoader;

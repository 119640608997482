import React from 'react';
import Divider from '../Divider';
import FooterLogo from './FooterLogo';
import FooterFollowUs from './FooterFollowUs';
import './Footer.scss';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const today = new Date();

  return (
    <footer className="Footer">
      <div className="Footer__header">
        <FooterLogo />

        <FooterFollowUs />
      </div>

      <Divider />

      <div className="Footer__text">
        Renaissance Capital LLC is an SEC-registered investment adviser.
        <br />
        <Link className="FooterFollowUs__Disclosurelink" to={'/disclosures'}>
          {' '}
          Important Disclosures
        </Link>
        <br />
        &#169; {today.getFullYear()} Renaissance Capital LLC. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;

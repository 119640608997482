import React from 'react';

import './LinkButton.scss';

export interface ButtonIconProps {
  linkUrl?: string;
  linkText?: string;
}

const ButtonIcon: React.FC<ButtonIconProps> = props => {
  return (
    <span className="link-button btn btn-sm text-uppercase">
      <a href={props.linkUrl} rel="nofollow noopener noreferrer">
        {props.linkText}
      </a>
    </span>
  );
};

export default ButtonIcon;

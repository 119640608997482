import Highcharts from 'highcharts';
import { BreakpointSizes } from '../../../enums/BreakpointSizes';
import { ChartTooltipFormatterService } from '../../../components/chart/services/ChartTooltipFormatterService';
import { CustomAny } from '../../../types/generics';
import { ChartValuesType } from '../../../components/chart/enums/ChartValuesType';
import { HtmlString } from '../../../types/HtmlString';
import { NumberHelper } from '../../../helpers/NumberHelper';
import { NumberFormat } from '../../../enums/NumberFormat';

export const annualRatingsPerformanceChart: Highcharts.Options = {
  chart: {
    spacingTop: 0,
    marginTop: 50,
  },
  title: {
    text: 'Annual Ratings Performance',
  },
  subtitle: {
    text: '3-Month Returns from Midpoint',
  },
  yAxis: {
    title: {
      text: 'Avg. 3-Month Return vs. Midpoint (%)',
    },
    labels: {
      formatter() {
        return NumberHelper.getFormatValue(this.value, NumberFormat.PercentageInteger);
      },
    },
    max: 1.5,
    min: -1,
  },
  xAxis: {
    categories: [
      '2007',
      '2008',
      '2009',
      '2010',
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
      '2022',
      '2023',
    ],
  },
  series: [
    {
      type: 'column',
      name: 'Outperform',
      data: [0.52, 0.26, 0.22, 0.54, 0.43, 0.56, 0.75, 0.56, 0.42, 0.79, 0.41, 0.72, 1.03, 1.59, 0.44, 0.33, 0.25],
    },
    {
      type: 'column',
      name: 'Underperform',
      data: [
        -0.01,
        -0.51,
        -0.03,
        -0.28,
        -0.01,
        -0.26,
        0.37,
        -0.17,
        -0.27,
        -0.12,
        -0.22,
        -0.11,
        -0.03,
        -0.48,
        -0.06,
        null,
        -0.34,
      ],
    },
  ],
  tooltip: {
    formatter() {
      return ChartTooltipFormatterService.getTooltip({
        points: this.points as CustomAny[],
        title: `${this.x}`,
        pointsValueType: ChartValuesType.Percentage,
      });
    },
  },
  plotOptions: {
    column: {
      dataLabels: {
        formatter(): HtmlString {
          return NumberHelper.getFormatValue(this.y, NumberFormat.PercentageInteger);
        },
      },
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: BreakpointSizes.ExtraSmall,
        },
        chartOptions: {
          yAxis: {
            visible: false,
          },
        },
      },
    ],
  },
  credits: {
    enabled: false,
  },
};

import React from 'react';
import TextContainer from '../../shared/TextContainer';
import './AboutUnit.scss';

export interface AboutUnitProps {
  title: string;
  description: string;
  link?: string;
}

const AboutUnit: React.FC<AboutUnitProps> = props => {
  return (
    <section className="AboutUnit">
      <div className="AboutUnit__title">
        {props.link ? (
          <a
            href={`${props.link}`}
            className="AboutUnit__title-link"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {props.title}
          </a>
        ) : (
          <>
            <span className="AboutUnit__title-text ">{props.title}</span>
          </>
        )}
      </div>

      <div className="AboutUnit__content">
        <TextContainer>{props.description}</TextContainer>
      </div>
    </section>
  );
};

export default AboutUnit;

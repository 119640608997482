import React from 'react';
import Icon from '../../shared/components/icon/Icon';
import { IconName } from '../../shared/components/icon/IconName';
import './OurClient.scss';

export interface OurClientProps {
  icon: IconName;
  description: string;
}

const OurClient: React.FC<OurClientProps> = props => {
  return (
    <div className="OurClient">
      <div className="OurClient__icon">
        <Icon name={props.icon} />
      </div>

      <div className="OurClient__description">{props.description}</div>
    </div>
  );
};

export default OurClient;

export enum IconName {
  Video = 'video',
  LeftArrow = 'left-arrow',
  RightArrow = 'right-arrow',
  DownArrow = 'down-arrow',
  UpArrow = 'up-arrow',
  Increase = 'up',
  Decrease = 'down',
  Eye = 'eye',
  GrowingChart = 'growing-chart',
  Book = 'book',
  CircleCheck = 'circle-check',
  Suitcase = 'suitcase',
  Manager = 'manager',
  ChartBoard = 'chart-board',
  Bank = 'bank',
  Money = 'money',
  Magnifier = 'magnifier',
  BoldMagnifier = 'bold-magnifier',
  Spyglass = 'spyglass',
  Chess = 'chess',
  Clock = 'clock',
  Check = 'check',
  Binoculars = 'binoculars',
  Processor = 'processor',
  Twitter = 'twitter',
  LinkedIn = 'linkedin',
  Facebook = 'facebook',
  Youtube = 'youtube',
  StarFilled = 'star-filled',
  Star = 'star',
  Chat = 'chat',
  User = 'user',
  Question = 'question',
  Plus = 'plus',
  Filter = 'filter',
  FilterPlus = 'filter-plus',
  Printing = 'printing',
  Excel = 'excel',
  Save = 'save',
  Link = 'link',
  VideoCamera = 'videocamera',
  MoneyPig = 'business-and-finance',
  Manufacturing = 'factory',
  Group = 'users-group',
  Pdf = 'pdf',
  MgmtQ = 'MgmtQ',
  TransitionArrow = 'curve-arrow',
  MenuDots = 'menu-dots',
  Close = 'close',
  Paper = 'paper',
  Minus = 'minus',
  Calendar = 'calendar',
  Office = 'urban',
  Newspaper = 'newspaper',
  Sheet = 'sheet',
}

import React from 'react';
import { clients } from './clients';
import OurClient, { OurClientProps } from './OurClient';
import './OurClients.scss';

const OurClients: React.FC = () => {
  return (
    <div className="OurClients">
      <h4 className="OurClients__title">Our Clients</h4>

      <ul className="OurClients__list">
        {clients.map((props: OurClientProps, index: number) => (
          <li className="OurClients__item" key={index}>
            <OurClient {...props} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OurClients;

import React from 'react';
import './DescriptionList.scss';

interface Props {
  items: React.ReactNode[];
}

const DescriptionList: React.FC<Props> = props => {
  return (
    <div className="DescriptionList">
      <ul className="DescriptionList-list">
        {props.items?.map((item: React.ReactNode, index: number) => (
          <li className="DescriptionList-list__item" key={index}>
            <p>{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DescriptionList;

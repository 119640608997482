import React, { useCallback } from 'react';
import './PricingDescription.scss';
import items from './items';
import additionalItems from './additionalItems';
import { PricingTextElement } from './enum/PricingTextElement';
import LocationService from '../../shared/services/LocationService';
import { IconName } from '../../shared/components/icon/IconName';
import Icon from '../../shared/components/icon/Icon';
import { IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US } from '../../shared/constants/urls';

const PricingDescription: React.FC = () => {
  const onContactClick = useCallback(e => {
    e.preventDefault();
    LocationService.redirectExternalLink(IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US + '/?pricing=true', true);
  }, []);
  const today = new Date();

  return (
    <div className="PricingDescription">
      <h4 className="PricingDescription__title mt-0">Pricing</h4>
      <p>
        Our unbiased and trusted analysis provides you with valuable IPO insights, saves you time, and gives you the
        assurance of knowing that you will never miss an important IPO.
      </p>

      <h4 className="PricingDescription__title PricingDescription__title--dark">
        {today.getFullYear()} IPO Intelligence Pricing
      </h4>

      <div className="PricingDescription-plan">
        <span className="PricingDescription-plan__name">Base Annual Rate (USD)</span>
        <span className="PricingDescription-plan__price">$36,900</span>
        <span className="PricingDescription-plan__description">(Includes up to 6 users)</span>
      </div>

      <h4 className="PricingDescription__title">Included services</h4>
      <ul className="PricingDescription-list">
        {items.map((item: string, index: number) => (
          <li className="PricingDescription-list__item" key={index}>
            <Icon name={IconName.CircleCheck} /> {item}
          </li>
        ))}
      </ul>
      <h4 className="PricingDescription__title">Additional services</h4>
      <ul className="PricingDescription-list">
        {additionalItems.map((item: string, index: number) => (
          <li className="PricingDescription-list__item" key={index}>
            <Icon name={IconName.CircleCheck} /> {item}
          </li>
        ))}
      </ul>
      <p>
        To request a pricing sheet and detailed description of the features above, please click{' '}
        <a
          href={IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US}
          className="PricingDescription__link"
          rel="nofollow noopener noreferrer"
          onClick={onContactClick}
        >
          {PricingTextElement.ContactUs}
        </a>
        .
      </p>
    </div>
  );
};
export default PricingDescription;

import React, { useCallback, useMemo, useState } from 'react';
import ResearchFlowItem, { ResearchFlowProps } from './ResearchFlowItem';
import './ResearchFlow.scss';
import { LightBoxContext, LightBoxContextValue } from '../../../shared/components/lightbox/LightBoxContext';
import { Url } from '../../../shared/types';
import LightBox from '../../../shared/components/lightbox/LightBox';

interface Props {
  title: string;
  items: ResearchFlowProps[];
}

const ResearchFlow: React.FC<Props> = props => {
  const [images, setImages] = useState<Url[]>([]);
  const [index, setIndex] = useState<number>(0);

  const contextValue: LightBoxContextValue = useMemo(
    () => ({
      images,
      setImages,
      index,
      setIndex,
    }),
    [images, setImages, index, setIndex],
  );

  const openLightBox = useCallback(
    (index: number) => {
      setIndex(index);
      setImages(props.items.map(item => item.imageUrl));
    },
    [props.items, setIndex, setImages],
  );

  return (
    <LightBoxContext.Provider value={contextValue}>
      <div className="ResearchFlow">
        <h5 className="ResearchFlow__title">{props.title}</h5>

        <ul className="ResearchFlow__list">
          {props.items.map((itemProps: ResearchFlowProps, index: number) => (
            <ResearchFlowItem key={index} {...itemProps} openLightBox={(): void => openLightBox(index)} />
          ))}
        </ul>

        {!!images.length && <LightBox />}
      </div>
    </LightBoxContext.Provider>
  );
};

export default ResearchFlow;

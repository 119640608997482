import { identity, pickBy } from 'lodash';
import { stringify, parse, ParsedQuery, StringifyOptions, parseUrl, ParsedUrl } from 'query-string';
import { LocationSearchData } from '../interfaces/LocationSearchData';

const LOCATION_QUERY_STRING_BASE_OPTIONS: StringifyOptions = {
  arrayFormat: 'none',
};

export class LocationQueryStringService {
  static getQueryStringFromSearchData(searchData: LocationSearchData): string {
    return stringify(pickBy(searchData, identity), LOCATION_QUERY_STRING_BASE_OPTIONS);
  }

  static getSearchDataFromQueryString(queryString: string): ParsedQuery {
    return parse(queryString, LOCATION_QUERY_STRING_BASE_OPTIONS);
  }

  static getParsedUrlFromRoutePath(routePath: string): ParsedUrl {
    return parseUrl(routePath, LOCATION_QUERY_STRING_BASE_OPTIONS);
  }
}

import React from 'react';
import TextContainer from '../shared/TextContainer';
import './ResearchIpoLifecycle.scss';

const ResearchIpoLifecycle: React.FC = () => {
  return (
    <div className="ResearchIpoLifecycle">
      <TextContainer>
        <p className="ResearchIpoLifecycle__paragraph">
          <b>In-Depth Pre-IPO Research: </b>
          Our Pre-IPO research gives you an actionable investment opinion on every IPO. Within days of an IPO filing
          terms, you’ll have an in-depth but highly scannable look at the business, management team and shareholder
          makeup, valuation and relevant comparables. You’ll get the real bull and the bear case for every deal,
          relevant industry insights and meeting prep questions for management meetings.
        </p>

        <p className="ResearchIpoLifecycle__paragraph">
          <b>DCF Models and Comp Sheets: </b>
          Our Excel DCF models allows users to scrutinize line-by-line our forward financial estimates, measure the
          effect of different growth forecasts on our fair value estimate, and integrate the data with their own
          investment platforms. It also integrates with our comp sheets, letting you see the impact of changes in
          estimates or multiples and allowing you to customize the peer group.
        </p>

        <p className="ResearchIpoLifecycle__paragraph">
          <b>Improved Aerial View: </b>
          Our detailed calendars provide a dashboard for all upcoming U.S. and non-U.S. new listings, as well as quiet
          period and insider lock-up expirations. Our clients also receive regular top down IPO market commentary,
          including weekly, monthly, quarterly and annual IPO reviews.
        </p>

        <p className="ResearchIpoLifecycle__paragraph">
          <b>Comprehensive Coverage: </b>
          We cover all industries, market capitalizations and institutionally investable foreign listings. Each
          prospective IPO flows through the multi-step coverage process shown above.
        </p>
      </TextContainer>
    </div>
  );
};

export default ResearchIpoLifecycle;

import React from 'react';
import RequestTrial from '../shared/RequestTrial';
import Video from '../shared/Video';
import HeaderLine from '../shared/HeaderLine';
import PricingDescription from './PricingDescription';
import PricingDescriptionList from './PricingDescriptionList';
import './Pricing.scss';
import DocumentHead from '../../shared/components/DocumentHead';
import CarouselWithAllClients from '../shared/CarouselWithAllClients';
import { RoutePath } from '../../shared/enums/RoutePath';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import { ColorScheme } from '../../shared/enums/ColorScheme';
import { PrimarySectionIndent } from '../../shared/components/primary-section/PrimarySectionIndent';

const Pricing: React.FC = () => {
  return (
    <div className="Pricing">
      <DocumentHead path={RoutePath.Pricing} />

      <PrimarySection>
        <PricingDescription />
      </PrimarySection>

      <PrimarySection colorScheme={ColorScheme.Primary} indent={PrimarySectionIndent.Internal}>
        <HeaderLine>Get a team of IPO experts for a fraction of the cost of hiring an analyst</HeaderLine>
      </PrimarySection>

      <PrimarySection>
        <CarouselWithAllClients />
        <PricingDescriptionList />
      </PrimarySection>

      {/* <PrimarySection colorScheme={ColorScheme.Gray}>
        <Video
          title="See the full capabilities of the IPO intelligence platform with a video walkthrough"
          youTubeVideoId="WFP8E_e4er8"
        />
      </PrimarySection> */}

      <RequestTrial />
    </div>
  );
};

export default Pricing;

import React from 'react';
import Feature from '../shared/Feature';
import RequestTrial from '../shared/RequestTrial';
import Video from '../shared/Video';
import MultipleQuotes from '../shared/MultipleQuotes';
import OurClients from './OurClients';
import DocumentHead from '../../shared/components/DocumentHead';
import CarouselWithAllClients from '../shared/CarouselWithAllClients';
import { OptimizedPictureType } from '../shared/optimized-picture/enums/OptimizedPictureType';
import { RoutePath } from '../../shared/enums/RoutePath';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import { ColorScheme } from '../../shared/enums/ColorScheme';

const WhoUsesUs: React.FC = () => {
  return (
    <div className="WhoUsesUs">
      <DocumentHead path={RoutePath.WhoUsesUs} />

      <Feature
        header="Trusted by the smartest investors"
        optimizedPictureType={OptimizedPictureType.WhoUsesUsBusinessmen}
      >
        The Renaissance Capital IPO Intelligence research platform brings you comprehensive, reliable
        <span className="d-lg-block d-xl-block" /> pre-IPO research on U.S. and global deals to help you see
        tomorrow&apos;s opportunities, today.
      </Feature>

      <PrimarySection>
        <OurClients />
      </PrimarySection>

      <PrimarySection>
        <CarouselWithAllClients />
      </PrimarySection>

      {/* <PrimarySection colorScheme={ColorScheme.Gray}>
        <Video
          title="See why our clients find IPO intelligence so valuable with the web demo"
          youTubeVideoId="WFP8E_e4er8"
        />
      </PrimarySection> */}

      <PrimarySection>
        <MultipleQuotes />
      </PrimarySection>

      <RequestTrial title="Request your free trial now" button="Sign up today" />
    </div>
  );
};

export default WhoUsesUs;

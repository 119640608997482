import React, { FunctionComponent } from 'react';
import QuotesItem from './quotes/QuotesItem';
import Quotes from './quotes/Quotes';

const MultipleQuotes: FunctionComponent = () => {
  return (
    <Quotes>
      <QuotesItem
        quote="Their research is essential to helping us allocate resources and ensuring we don’t miss a deal."
        author="Top 25 Hedge Fund"
      />
      <QuotesItem
        quote="Their IPO research has been an integral part of our investment process for over 15 years."
        author="$200B+ Asset Manager"
      />
      <QuotesItem
        quote="Renaissance has cornered the market for smart and timely pre-IPO research."
        author="SMID Cap Fund Manager"
      />
      <QuotesItem
        quote="It makes my job easier with timely tracking, intelligent commentary and unbiased analysis."
        author="$100B+ Pension Fund"
      />
      <QuotesItem quote="They know how institutional investors look at deals." author="Alternative Asset Manager" />
      <QuotesItem
        quote="The 800 pound gorilla in IPO research and investment management."
        author="Investor Business Daily"
      />
    </Quotes>
  );
};

export default MultipleQuotes;

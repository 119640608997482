import Highcharts from 'highcharts';
import { ChartTooltipFormatterService } from '../../services/ChartTooltipFormatterService';
import { CustomAny } from '../../../../types/generics';
import { ChartValuesType } from '../../enums/ChartValuesType';
import { NumberHelper } from '../../../../helpers/NumberHelper';
import { NumberFormat } from '../../../../enums/NumberFormat';

export const verticalCurrencyChartOptions: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  yAxis: {
    stackLabels: {
      enabled: true,
      formatter() {
        return NumberHelper.getFormatValue(this.total, NumberFormat.CurrencyIntegerWithLetter);
      },
    },
  },
  tooltip: {
    formatter() {
      return ChartTooltipFormatterService.getTooltip({
        points: this.points as CustomAny[],
        title: `${this.x}`,
        pointsValueType: ChartValuesType.Currency,
      });
    },
    shared: true,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
};

import React from 'react';
import itemsList from './itemsList';
import DescriptionList from '../shared/DescriptionList';
import './PricingDescriptionList.scss';

const PricingDescriptionList: React.FC = () => {
  return (
    <div className="PricingDescriptionList">
      <div className="PricingDescriptionList__content">
        <div className="PricingDescriptionList-list">
          <div className="PricingDescriptionList-list__title">IPO Intelligence is</div>

          <DescriptionList items={itemsList} />
        </div>
      </div>
    </div>
  );
};

export default PricingDescriptionList;

import './polyfills';
import React from 'react';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';

import { hydrate, render } from 'react-dom';

const root = document.getElementById('root') as HTMLDivElement;

(root.hasChildNodes() ? hydrate : render)(
  <Provider store={store}>
    <App />
  </Provider>,
  root,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import './PrimarySectionRow.scss';
import { AlignTypesHorizontal } from '../../enums/AlignTypesHorizontal';
import { GridType } from './enum/GridType';
import { useClassName } from '../../hooks/useClassName';
import { PrimarySectionRowBlock, PrimarySectionRowElement, PrimarySectionRowModifier } from './primarySectionRowBem';

interface Props {
  className?: string;
  alignHorizontal?: AlignTypesHorizontal;
  gridType?: GridType;
  header?: React.ReactNode;
  isHeaderNoBorder?: boolean;
}

const PrimarySectionRow: React.FC<Props> = props => {
  const cn = useClassName(PrimarySectionRowBlock.Root, props.className);

  return (
    <div
      className={cn({
        [`${props.gridType}`]: props.gridType,
      })}
    >
      {props.header && (
        <div className={cn(PrimarySectionRowElement.Header)}>
          <div
            className={cn(PrimarySectionRowElement.HeaderContent, {
              [PrimarySectionRowModifier.NoBorder]: props.isHeaderNoBorder,
            })}
          >
            {props.header}
          </div>
        </div>
      )}
      <div
        className={cn(PrimarySectionRowElement.Content, {
          [`${props.alignHorizontal}`]: props.alignHorizontal,
        })}
      >
        {props.children}
      </div>
    </div>
  );
};

export default PrimarySectionRow;

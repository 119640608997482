import React, { FunctionComponent, useCallback, useEffect } from 'react';
import './HeaderMainMenu.scss';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { closeMobileMenu, toggleMobileMenu } from '../../../store/header/actions';
import escEffect from '../../effects/escEffect';

const HeaderMainMenu: FunctionComponent = () => {
  const dispatch = useDispatch();
  const onButtonClick = useCallback(() => dispatch(toggleMobileMenu()), [dispatch]);
  const isMenuOpen = useSelector<RootState, boolean>(state => state.header.isMobileMenuOpen);

  useEffect(escEffect(() => dispatch(closeMobileMenu()), isMenuOpen));

  return (
    <nav className="HeaderMainMenu">
      <button className="HeaderMainMenu__toggle btn btn-sm" onClick={onButtonClick}>
        {isMenuOpen ? <i className="mdi mdi-close" /> : <i className="mdi mdi-menu" />}
      </button>
    </nav>
  );
};

export default HeaderMainMenu;

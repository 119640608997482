import React from 'react';
import './ResearchProcessHeader.scss';

const ResearchProcessHeader: React.FC = () => {
  return (
    <h3 className="ResearchProcessHeader">
      The research process used by the
      <span className="ResearchProcessHeader__separator" />
      ipo experts at renaissance capital
    </h3>
  );
};

export default ResearchProcessHeader;

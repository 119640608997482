import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import './ScreenshotDescription.scss';
import classNames from 'classnames';
import TextContainer from './TextContainer';
import { IconName } from '../../shared/components/icon/IconName';
import { ColorScheme } from '../../shared/enums/ColorScheme';
import Icon from '../../shared/components/icon/Icon';
import LightBox from '../../shared/components/lightbox/LightBox';
import { Url } from '../../shared/types';
import { LightBoxContext, LightBoxContextValue } from '../../shared/components/lightbox/LightBoxContext';
import Modal from 'react-modal';

// TODO: Refactor page. Break code into reusable compnents. Import constant variables from an external file.

export interface ScreenshotDescriptionProps {
  title: ReactNode | string;
  description: ReactNode | string;
  icon?: IconName;
  imageUrl: string | string[];
  baseImageUrl?: string;
  reverse?: boolean;
  colorScheme?: ColorScheme;
  header?: boolean;
  layoutStyle?: string;
  isVideo?: boolean;
}

const videoCardStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: '',
  },
  overlay: {
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const ScreenshotDescription: React.FC<ScreenshotDescriptionProps> = props => {
  const [images, setImages] = useState<Url[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [modalIsOpen, setIsModalOpen] = React.useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  const contextValue: LightBoxContextValue = useMemo(
    () => ({
      images,
      setImages,
      index,
      setIndex,
    }),
    [images, setImages, index, setIndex],
  );

  const openLightBox = useCallback(
    (index: number) => {
      setIndex(index);
      if (Array.isArray(props.imageUrl)) {
        setImages(props.imageUrl.map(item => item));
      }
    },
    [props.imageUrl, setIndex, setImages],
  );

  const titleWithIcon = (
    <>
      {props.icon && (
        <div
          className={`ScreenshotDescription__icon${props.icon === 'sheet' ? ' sheet-icon' : ''}${
            props.layoutStyle ? ` in-${props.layoutStyle}-icon` : ''
          }`}
        >
          <Icon name={props.icon} />
        </div>
      )}
      <div className={`ScreenshotDescription__title${props.layoutStyle ? ` in-${props.layoutStyle}-title` : ''}`}>
        {props.title}
      </div>
    </>
  );

  return (
    <LightBoxContext.Provider value={contextValue}>
      <div className="ScreenshotDescription">
        {props.header && <header className="ScreenshotDescription__header">{titleWithIcon}</header>}

        <div
          className={`${classNames('ScreenshotDescription__container', {
            'ScreenshotDescription__container--reverse': props.reverse,
          })}${props.layoutStyle ? ` ${props.layoutStyle}` : ''}`}
        >
          <div
            className={`ScreenshotDescription__content${props.layoutStyle ? ` in-${props.layoutStyle}-content` : ''}`}
          >
            {props.header || titleWithIcon}

            <TextContainer>
              <div
                className={`ScreenshotDescription__description${
                  props.layoutStyle ? ` in-${props.layoutStyle}-description` : ''
                }`}
              >
                {props.description}
              </div>
            </TextContainer>
          </div>

          <div
            className={`ScreenshotDescription__image${props.layoutStyle ? ` in-${props.layoutStyle}-image` : ''}${
              props.isVideo ? ' video-tint-container' : ''
            }`}
          >
            {props.isVideo ? (
              <>
                <span onClick={openModal} className={'img-with-tint'}>
                  <img
                    onClick={openModal}
                    src="https://cdn.loom.com/sessions/thumbnails/60ac399057484e50ae316977b3f9955f-with-play.gif"
                  />
                </span>

                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={videoCardStyle}
                  contentLabel="Video Modal"
                >
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/_0uw1TEt4Ik?rel=0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                  <span className={'btn-close-modal'} onClick={() => setIsModalOpen(false)}>
                    <span className={'btn-text'}> &#10006;</span>
                  </span>
                </Modal>
              </>
            ) : Array.isArray(props.imageUrl) ? (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => openLightBox(index)}
                src={props.baseImageUrl ? props.baseImageUrl : props.imageUrl[0]}
                alt=""
              />
            ) : (
              <img src={props.imageUrl} alt="" />
            )}
            {!!images.length && <LightBox />}
          </div>
        </div>
      </div>
    </LightBoxContext.Provider>
  );
};

export default ScreenshotDescription;

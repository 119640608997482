import React from 'react';
import './ResearchProcess.scss';
import { Link } from 'react-router-dom';
import TextContainer from '../shared/TextContainer';
import { ResearchTextElement } from './enum/ResearchTextElement';
import { RoutePath } from '../../shared/enums/RoutePath';
import { IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US } from '../../shared/constants/urls';

const ResearchProcess: React.FC = () => {
  return (
    <div className="ResearchProcess">
      <TextContainer>
        <p className="ResearchProcess__paragraph">
          IPO Intelligence is the leading independent pre-IPO research platform for institutional investors, financial
          sponsors, investment banks and IPO advisory firms. Our service provides real-time IPO alerts and in-depth
          fundamental research, valuation analysis and financial models on every US IPO and major global listings.
        </p>

        <p className="ResearchProcess__paragraph">
          It is the premier resource for comprehensive tracking of the US and global IPO markets. The top institutional
          investors rely on IPO Intelligence to help identify and analyze these new investment opportunities.
        </p>

        <p className="ResearchProcess__paragraph">
          Our research can be tailored to our clients’ areas of interest, including small vs large cap, growth vs value,
          U.S. vs International, sector focus, etc. Clients are able to consult with our research analysts and request
          custom data sets.
        </p>

        <p className="ResearchProcess__paragraph">
          <a href={IPO_INTELLIGENCE_CLIENT_SITE_CONTACT_US} rel="nofollow noopener noreferrer">
            {ResearchTextElement.ContactUs}&nbsp;
          </a>
          to learn more about our IPO Intelligence Research platform.
        </p>
      </TextContainer>
    </div>
  );
};

export default ResearchProcess;

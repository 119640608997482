import { ReducerAction } from '../../shared/interfaces/ReducerAction';
import { Notification } from '../../shared/components/notification/interfaces/Notification';
import { NotificationAdd } from '../../shared/components/notification/interfaces/NotificationAdd';
import { UUID } from '../../shared/types';
import { CustomAny } from '../../shared/types/generics';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export type State = {
  notifications: Notification[];
};

export type AddNotificationAction = ReducerAction<typeof ADD_NOTIFICATION, { toast: NotificationAdd }>;
export type RemoveNotificationAction = ReducerAction<typeof REMOVE_NOTIFICATION, { id: UUID }>;
export type ClearNotifications = ReducerAction<typeof CLEAR_NOTIFICATIONS, {}>;

export type ActionTypes = AddNotificationAction | RemoveNotificationAction | ClearNotifications;

export type NotificationActionFn = () => Promise<void>;

export interface NotificationSuccessMessage {
  text: string;
  values: string[];
}

export type GetNotificationSuccessMessageFn = (data: CustomAny) => NotificationSuccessMessage;

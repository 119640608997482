import { useCallback } from 'react';
import { ClassNameFn } from '../interfaces/ClassNameFn';
import { ClassDictionary } from 'classnames/types';
import classNames from 'classnames';
import mapKeys from 'lodash/mapKeys';
import { BemService } from '../services/BemService';

export function useClassName(component: string, additionalComponent?: string): ClassNameFn {
  return useCallback<ClassNameFn>(
    (elementOrModifiers?: string | ClassDictionary, optionalModifiers?: ClassDictionary) => {
      const block = classNames(component);
      let element: string | undefined;
      let modifiers;

      let modifiersDictionary: ClassDictionary | null = null;

      if (typeof elementOrModifiers === 'string') {
        element = BemService.getElement(component, elementOrModifiers);
      }

      if (typeof elementOrModifiers === 'object') {
        modifiersDictionary = elementOrModifiers;
      } else if (typeof optionalModifiers === 'object') {
        modifiersDictionary = optionalModifiers;
      }

      if (modifiersDictionary) {
        modifiers = mapKeys(modifiersDictionary, (value, modifier) => `${element || block}--${modifier}`);
      }

      return element ? classNames(element, modifiers) : classNames(block, modifiers, additionalComponent);
    },
    [component, additionalComponent],
  );
}

import React from 'react';
import Carousel from '../../shared/components/carousel/Carousel';
import CarouselItem from '../../shared/components/carousel/CarouselItem';

const CarouselWithAllClients: React.FC = () => (
  <Carousel>
    <CarouselItem>
      <img src={require('./images/clients/jefferies-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/deutsche-bank-logo.png')} alt="" />
    </CarouselItem>

    <CarouselItem>
      <img src={require('./images/clients/t-rowe-price-logo-c.png')} alt="" />
    </CarouselItem>

    {/* <CarouselItem><img src={require('./images/clients/morgan-logo.png')} alt="" /> </CarouselItem>*/}

    <CarouselItem>
      <img src={require('./images/clients/federated-logo.png')} alt="" />
    </CarouselItem>
  </Carousel>
);

export default CarouselWithAllClients;

import { Reducer } from 'redux';
import { ActionTypes, ADD_NOTIFICATION, CLEAR_NOTIFICATIONS, REMOVE_NOTIFICATION, State } from './types';
import { addNotification, removeNotification } from './helpers';

const initialState: State = {
  notifications: [],
};

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: addNotification(action.payload.toast, state.notifications),
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: removeNotification(action.payload.id, state.notifications),
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
};

import { IconName } from '../../shared/components/icon/IconName';
import { OurClientProps } from './OurClient';

export const clients: OurClientProps[] = [
  {
    icon: IconName.Money,
    description: 'Hedge Funds',
  },
  {
    icon: IconName.Bank,
    description: 'Investment Banks',
  },
  {
    icon: IconName.ChartBoard,
    description: 'Mutual Funds',
  },
  {
    icon: IconName.Manager,
    description: 'Alternative Asset Managers',
  },
  {
    icon: IconName.GrowingChart,
    description: 'Pension Funds',
  },
  {
    icon: IconName.Suitcase,
    description: 'RIAs',
  },
  {
    icon: IconName.Manufacturing,
    description: 'IPO Advisory Firms',
  },
  {
    icon: IconName.MoneyPig,
    description: 'VC/Private Equity Firms',
  },
  {
    icon: IconName.Group,
    description: 'Large Family Offices',
  },
];
